import React from "react";
import Header from "../Header";
import Herosec from "../Herosec";
import Services from "../Services";
import Footer from "../Footer";
import Subscribe from "../Subscribe";
import Join from "../Join";
import SyxSociety from "./SyxSociety";
// import SyxthSense from "./SyxthSense";

const Intro = () => {
  return (
    <div className="relative z-0">
      <Header />
      <Herosec />
      <Services />
      <SyxSociety />
      {/* <SyxthSense /> */}
      <Subscribe />
      <Join />
      <Footer />
    </div>
  );
};

export default Intro;
