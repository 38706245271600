import React from "react";
import Header from "../Header";

import illustration from "../../assets/illustrations.png";
import blockchain from "../../assets/blockchainimg.svg";

import Bsolutions from "./Solutions/Bsolutions";
import banner from "../../assets/Banner.png";

import Footer from "../Footer";

import Relblog from "./Relblog";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import {
  FADE_DOWN_ANIMATION_VARIANTS,
  variants1,
} from "../../constants/fmvariants";

const Blockchain = () => {
  return (
    <div className="">
      <Header />
      <section className=" flex flex-col sm:flex sm:flex-col bg-[#fff] mt-0 lg:mt-[80px]">
        <motion.div
          className="flex   lg:h-[520px]  sm:flex-row flex-col xl:justify-between "
          initial="hidden"
          animate="show"
          viewport={{ once: true }}
          variants={{
            hidden: {},
            show: {
              transition: {
                staggerChildren: 0.15,
              },
            },
          }}
        >
          <div className="flex flex-col mt-[40px] lg:px-[147px] ">
            <motion.img
              src={illustration}
              alt="illustration"
              className="flex lg:w-[375px] xl:justify-end lg:mt-18 sm:hidden"
              initial="hidden"
              animate="visible"
              transition={{ duration: 1 }}
              variants={variants1}
            />

            <motion.div
              variants={FADE_DOWN_ANIMATION_VARIANTS}
              className="border-[1px] border-primary w-[88px] h-[36px] py-[12px] px-[16px]  rounded-full flex items-center justify-center mx-4 lg:my-0 my-[24px]"
            >
              <p className="font-medium text-xs text-primary">SERVICES</p>
            </motion.div>

            <motion.div
              className="flex flex-col lg:h-72 px-4 lg:-mb-0 mb-[64px]"
              variants={FADE_DOWN_ANIMATION_VARIANTS}
            >
              <p className="font-light lg:text-[48px] text-[32px] max-w-lg mt-12 lg:mt-[32px]">
                Blockchain software Development
              </p>
              <p className="font-normal lg:mt-[112px] mt-[48px] text-secondary text-[16px]  lg:w-[482px]">
                Our blockchain development services can help you unlock the full
                potential of blockchain technology for your business
              </p>
              <div className=" mt-[24px]">
                <button className="flex justify-center items-center flex-col gap-4 text-[#A6F200] bg-[#003249] h-[40px] w-[140px] sm:w-[120px] sm:h-[40px] hover:bg-[#A6F200] hover:text-[#003249] cursor-pointer">
                  <p className="text-sm">
                    <Link to="/contact">Talk to us</Link>
                  </p>
                </button>
              </div>
            </motion.div>
          </div>

          <motion.div
            className="bg-[#E5F1FF] items-center  w-[720px]  sm:flex sm:justify-center hidden sm:block"
            initial="hidden"
            animate="visible"
            transition={{ duration: 1 }}
            variants={variants1}
          >
            <img src={illustration} alt="" className="  " />
          </motion.div>
        </motion.div>
        <div className=" bg-[#A6F200] h-full lg:h-[288px] flex items-center py-[48px]  lg:px-[147px] px-4  ">
          <p className="font-normal lg:text-[32px] text-[20px] lg:w-[706px] lg:h-[192px] h-full text-primary">
            We understand the importance of staying ahead in today’s competitive
            markets. We help our clients boost their operational workflow with
            blockchain software solutions
          </p>
        </div>
        <div className="">
          <Bsolutions />
        </div>
        <div
          className="w-full mt-12 relative h-[160px] mb-[100px] flex justify-center items-center"
          style={{
            backgroundImage: `url(${banner})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className=" w-full items-center  flex justify-center  lg:flex-row flex-col lg:space-x-[49px] lg:space-y-0 space-y-[16px] ">
            <p className="lg:text-[40px] text-[24px] font-light text-white">
              Take the next step.{" "}
            </p>
            <button
              className="items-center w-[120px] h-[42px] font-normal bg-[#A6F200] text-primary text-sm
            
            hover:bg-[black] hover:text-[#fff]  py-[12px] px-[12px]
            "
            >
              <Link to="/contact">Contact Us</Link>
            </button>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Blockchain;

// <img
//             src={illustration}
//             alt="illustration"
//             className="flex lg:w-3/6 lg:h-8/12 xl:justify-end lg:mt-18 hidden sm:block lg:block"
//           />

// <img
//               src={illustration}
//               alt="illustration"
//               className="flex lg:w-3/6 sm:w-44 xl:justify-end lg:mt-18 sm:hidden"
//             />
