import React, { useState } from "react";
import { v4 } from "uuid";

import logo from "../assets/logo.svg";
import instagram from "../assets/instagram.svg";
import linkedin from "../assets/linkedIn.svg";
import telegram from "../assets/telegram.svg";
import discord from "../assets/discord.svg";
import x from "../assets/x.svg";
import { Link } from "react-router-dom";
import axios from "axios";

import { FaCheck } from "react-icons/fa";

const Footer = () => {
  const [formValues, setFormValues] = useState({
    fullName: "",
    phoneNo: "",
    country: "",
    email: "",
    message: "",
    receiveInsights: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [isChecked, setIsChecked] = useState(formValues.receiveInsights);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleCheckboxChange = () => {
    setFormValues((prevValues) => ({
      ...prevValues,
      receiveInsights: !prevValues.receiveInsights,
    }));
    setIsChecked((prevIsChecked) => !prevIsChecked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    axios
      .post(
        "https://syx-server-747dfeee5139.herokuapp.com/v1/messageus",
        formValues
      )
      .then(() => {
        setIsSubmitted(true);
        setFormValues({
          fullName: "",
          email: "",
          phoneNo: "",
          country: "",
          message: "",
          receiveInsights: false,
        });
      })
      .catch((error) => {
        console.error("API error:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const products = [
    { title: "Zap Exchange", link: "https://zap.africa/" },
    { title: "Martha AI", link: "https://waitlist.askmartha.ai/" },
  ];

  const company = [
    { title: "About", link: "/about" },
    // { title: "Blog", link: "#" },
    { title: "Contact us", link: "/contact" },
    { title: "Careers", link: "/careers" },
  ];

  const d = new Date();

  const year = d.getFullYear();

  return (
    <footer className=" bg-[#003249] text-white w-[100%] lg:px-[6.25rem] px-[16px]">
      <div className="flex flex-col w-full justify-around ">
        <div className="flex sm:flex-row flex-col mt-14 justify-between gap-8 w-full">
          <div className="flex flex-col w-full md:w-1/2">
            <p className=" w-[180%] sm:w-full text-[20px] sm:text-[24px] max-w-[15.5rem] sm:max-w-[25rem] font-light">
              <span className="text-[#A6F200]">
                Unleash Your Business Potential with Syx Labs:
              </span>{" "}
              The Future of Technology at Your Fingertips.
            </p>
            {/*
            <div className="flex flex-col w-full lg:mt-[71px] mt-[48px]">
              <p className="font-medium text-[24px] lg:text-[32px]">
                Contact Us
              </p>
              <div className="flex sm:flex-col mt-[40px] lg:mt-[30px] w-full">
                <form onSubmit={handleSubmit} className="w-full flex flex-col">
                  <div className="flex flex-col lg:flex-row lg:space-x-[24px] lg:space-y-0 space-x-0 space-y-[46px]  ">
                    <label>
                      <input
                        type="text"
                        name="fullName"
                        value={formValues.fullName}
                        onChange={handleChange}
                        placeholder="Full Name*"
                        required
                        className="bg-transparent text-sm  w-[200%] sm:w-[269.5px] border-0 border-b border-[#7C9299] placeholder-[#7C9299] font-light py-3 text-white focus:outline-none focus:bg-trasparent focus:shadow-outline focus:border-gray-600"
                      />
                    </label>
                    <label>
                      <input
                        type="email"
                        name="email"
                        value={formValues.email}
                        onChange={handleChange}
                        placeholder="Email*"
                        required
                        className="bg-transparent text-sm
                        w-[200%] lg:w-[269.5px] border-0 border-b border-[#7C9299] placeholder-[#7C9299] font-light py-3 text-white focus:outline-none focus:bg-trasparent focus:shadow-outline focus:border-gray-600"
                      />
                    </label>
                  </div>
                  <div className="flex flex-col lg:flex-row lg:space-x-[24px] lg:space-y-0 space-y-[46px] space-x-0  my-[46px]">
                    <label>
                      <select
                        name="country"
                        value={formValues.country}
                        onChange={handleChange}
                        required
                        className=" text-sm bg-transparent lg:w-[269px] w-[200%] border-0 border-b border-[#7C9299] placeholder-[#7C9299] font-light py-3 text-[#7C9299] focus:outline-none focus:bg-trasparent focus:shadow-outline focus:border-gray-600"
                      >
                        {countries.map((country) => (
                          <option key={country.code} value={country.name}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </label>
                    <label>
                      <input
                        type="tel"
                        name="phoneNo"
                        value={formValues.phoneNo}
                        onChange={handleChange}
                        placeholder="Phone"
                        className="bg-transparent text-sm  w-[200%] lg:w-[269.5px] border-0 border-b border-[#7C9299] placeholder-[#7C9299] font-normal py-3 text-white focus:outline-none focus:bg-trasparent focus:shadow-outline focus:border-gray-600"
                      />
                    </label>
                  </div>

                  <label className=" ">
                    <input
                      type="text"
                      required
                      name="message"
                      value={formValues.message}
                      onChange={handleChange}
                      placeholder="Message"
                      className="bg-transparent lg:w-[568px] w-[200%] text-sm  border-0 border-b border-[#7C9299] placeholder-[#7C9299] font-light py-3 text-white focus:outline-none focus:bg-trasparent focus:shadow-outline focus:border-gray-600"
                    />
                  </label>

                  <button
                    type="submit"
                    className=" bg-[#A6F200] font-normal text-primary lg:w-48 w-[140px] h-10 mt-10 text-sm hover:bg-black hover:text-[#A6F200]"
                  >
                    {isLoading
                      ? "Loading..."
                      : isSubmitted
                      ? "Submitted!"
                      : "Send Message"}
                  </button>

                  <div className="flex flex-row mt-10 space-x-3 w-[180%] sm:w-full">
                    <label className="flex items-center space-x-2 cursor-pointer">
                      <div
                        className={`w-4 h-4 border mb-[23px] sm:mb-0 rounded-sm ${
                          isChecked
                            ? "border-[#A6F200] bg-[#A6F200]"
                            : "border-white bg-transparent"
                        }`}
                        onClick={handleChange}
                      >
                        {isChecked && (
                          <div className="flex items-center justify-center w-full h-full">
                            <FaCheck size={11} color="black" />
                          </div>
                        )}
                      </div>
                      <input
                        type="checkbox"
                        checked={formValues.receiveInsights}
                        name="receiveInsights"
                        onChange={handleCheckboxChange}
                        className="opacity-0 absolute w-4 h-4 cursor-pointer"
                      />
                    </label>
                    <label className="text-white max-w-[17rem] sm:max-w-lg font-light">
                      I want to receive SyxthSense Insights to my email.
                    </label>
                  </div>
                </form>
              </div>
            </div>
						*/}

            <div className="flex flex-wrap justify-start items-start w-full md:w-auto gap-16 mt-12">
              <div className="flex flex-col justify-start items-start gap-3">
                <p className="text-[#F9F9F9] text-[1.125rem]">Products</p>
                {products.map((el) => (
                  <a
                    key={v4()}
                    href={el.link}
                    className="whitespace-nowrap text-[#F9F9F9] text-[0.875rem] flex justify-start items-center gap-2"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {el.title}

                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none">
                      <path
                        d="M7.875 6.625L12.25 2.25M12.25 2.25H9.33333M12.25 2.25V5.16667M12.25 8.66667V11.5833C12.25 11.8928 12.1271 12.1895 11.9083 12.4083C11.6895 12.6271 11.3928 12.75 11.0833 12.75H2.91667C2.60725 12.75 2.3105 12.6271 2.09171 12.4083C1.87292 12.1895 1.75 11.8928 1.75 11.5833V3.41667C1.75 3.10725 1.87292 2.8105 2.09171 2.59171C2.3105 2.37292 2.60725 2.25 2.91667 2.25H5.83333"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>
                ))}
              </div>
              <div className="flex flex-col justify-start items-start gap-3">
                <p className="text-[#F9F9F9] text-[1.125rem]">Company</p>
                {company.map((el) => (
                  <Link
                    key={v4()}
                    to={el.link}
                    className="text-[#F9F9F9] text-[0.875rem] flex justify-start items-center gap-2"
                  >
                    {el.title}
                  </Link>
                ))}
              </div>
            </div>
          </div>

          <div className="flex sm:flex-col justify-between">
            <img
              src={logo}
              alt="logo"
              className="w-[111.5px] h-[80px] sm:h-18 sm:w-40 sm:h-28 mt-10 sm:mt-0"
            />
            <div className="flex flex-col space-y-5 sm:ml-32 mt-10 sm:mt-36 w-5">
              <a
                href="https://www.instagram.com/syxlabs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagram} alt="" />
              </a>

              <a
                href="https://www.linkedin.com/company/syx-labs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedin} alt="" />
              </a>
              <a
                href="https://twitter.com/syxlabs?s=21&t=XkKOZCOBbgAss1G5PzYuYg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={x} alt="" />
              </a>
              <a
                href="https://t.me/syxlabs"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={telegram} alt="" />
              </a>
              <a
                href="https://lnk.bio/go?d=https%3A%2F%2Fdiscord.gg%2FsvZDCZwuzA&hash=c023d003871439b37287bf87a0072360&id=4992003&ext=-1633351&timezone=America%2FNew_York&type=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={discord} alt="" />
              </a>
            </div>
          </div>
        </div>

        <div className="mb-8 md:mb-0 flex flex-col-reverse justify-between gap-6 sm:flex-row mt-44 text-[8px] text-[#C5D5EA] md:py-[20px] sm:justify-between">
          <p className="sm:text-xs text-[#C5D5EA] text-[14px] font-[400] ">
            ©SyxLabs {year}
          </p>
          <div className="flex flex-row justify-between sm:text-xs sm:space-x-8 text-[#C5D5EA] text-[14px] font-[400] my-auto md:my-[20px]">
            {/* <Link to="/careers">Careers</Link> */}
            <Link to="/privacy">Privacy Policy</Link>
            {/* <Link to="#">Terms of Use</Link> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
