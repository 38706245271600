import React from "react";

import image from "../../assets/img.png";

import Header from "../Header";

const Blogs = () => {
  return (
    <div className="bg-[#F5F5F5]">
      <Header />
      <section className="sm:mx-28 mx-5">
        <div className="flex mt-10 py-20 flex-col  w-full">
          <div className="border-2 border-black items-center flex justify-center rounded-full w-16 h-8">
            <p className="text-xs font-normal">BLOG</p>
          </div>
          <p className="mt-7 text-6xl font-extralight">SyxthSense Blog</p>
          <div className="flex mt-12 flex-row hover:bg-white px-3 items-center space-x-8 pb-10 hover:w-full hover:h-[22rem]">
            <img
              src={image}
              alt=""
              className="w-full sm:w-3/6 h-48 sm:h-80 mt-10"
            />
            <div className="flex flex-col ">
              <p className="text-xs font-bold">3rd May 2023</p>
              <div className="flex flex-row space-x-2 mt-5">
                <button className="items-center rounded-full border-2 border-[#989898] w-20 h-7 ">
                  <p className="text-xs font-light">Agriculture</p>
                </button>
                <button className="items-center rounded-full border-2 border-[#989898] w-20 h-7 ">
                  <p className="text-xs font-light">Blockchain</p>
                </button>
              </div>
              <p className="text-2xl mt-8 font-medium text-[#474545]">
                The value of data-driven design
              </p>
              <p className="text-md font-light mt-3 max-w-sm">
                Lorem ipsum dolor sit amet consectetur. Elementum libero et
                auctor vel viverra a. Lacus ac cursus arcu purus praesent diam
                euismod libero.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Blogs;
