// import video from "../../assets/video/zap-video.mp4";
import gif from "../../assets/video/zap.gif";

const Zap = () => {
  return (
    <div
      className="w-full grid grid-cols-1 md:grid-cols-2 justify-start items-start sticky md:top-[3rem]"
      style={{ minHeight: "calc(100vh - 76px)" }}
      id="products"
    >
      <div className="bg-[#F9F9F9] text-center py-[4.3rem] px-[3.8rem] h-full md:flex-1 flex flex-col gap-[3rem] md:gap-[5rem] justify-center items-center">
        <svg width="66" height="16" viewBox="0 0 66 16" fill="none">
          <g clip-path="url(#clip0_2921_5159)">
            <path
              d="M1.8302 5.576C1.8302 5.576 3.27265 4.97159 7.03776 0.046875L23.9051 0.114978L14.4051 10.9306H10.7951L17.4878 2.84762H9.05408L6.46388 5.81437L1.82633 5.79734V5.57175L1.8302 5.576ZM22.0788 10.1176L17.4412 10.1006L14.851 13.0674H6.41735L13.5482 4.44805H9.93816L0 15.8085L16.8673 15.8766C20.6324 10.9519 22.0749 10.3432 22.0749 10.3432V10.1176H22.0788Z"
              fill="#3C42D1"
            />
            <path
              d="M32.2736 0H36.1279L26.3836 15.851H22.5293L32.2736 0ZM36.9111 1.43442L34.984 4.57143L38.276 9.92604H31.6919L29.9897 12.6927H39.9783L41.9636 15.9276H45.8179L36.9073 1.43442H36.9111Z"
              fill="#3C42D1"
            />
            <path
              d="M65.1435 4.21814V7.20192C65.1435 9.47486 63.4645 11.3136 61.3978 11.3136H54.5733L56.4151 8.31711H60.0639C60.1337 8.31711 60.2074 8.31711 60.2772 8.30434C61.1419 8.18941 61.8088 7.38494 61.8088 6.4017V5.0141C61.8088 3.9585 61.0294 3.0987 60.0639 3.0987H59.618L59.6258 3.08593H52.6617V0.106411H61.3939C63.1039 0.106411 64.5464 1.36632 64.9962 3.08593C65.0892 3.44773 65.1396 3.82655 65.1396 4.21814H65.1435ZM51.0409 0H47.8535V16H51.0409V0Z"
              fill="#3C42D1"
            />
          </g>
          <defs>
            <clipPath id="clip0_2921_5159">
              <rect width="65.1429" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <p className="text-[1.75rem] md:text-[2.5rem] text-[#0E0E0E] max-w-sm">
          Fast & Efficient crypto swaps
        </p>
        <p className="text-base md:text-[1.125rem] text-[#0E0E0E] max-w-sm">
          Swap cryptocurrency and fiat from anywhere in the world at the best
          rates
        </p>
        <a
          href="https://zap.africa"
          className="bg-[#CEFF64] text-[#0E0E0E] text-[1.25rem] px-[3rem] py-[1rem] rounded-[0.25rem] flex justify-center items-center gap-1 mt-8"
          target="_blank"
          rel="noreferrer noopener"
        >
          Visit Zap
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
              d="M9.99967 16.6666L8.81217 15.5L13.4788 10.8333H3.33301V9.16665H13.4788L8.81217 4.49998L9.99967 3.33331L16.6663 9.99998L9.99967 16.6666Z"
              fill="#0E0E0E"
            />
          </svg>
        </a>
      </div>
      <div className="bg-[#e4f0fe] pt-[4.3rem] px-[3.8rem] md:flex-1 flex flex-col justify-end items-center h-full">
        <img src={gif} alt="zap" />
        {/* <video src={video} alt="zap" loop autoPlay muted /> */}
      </div>
    </div>
  );
};

export default Zap;
