import "./App.css";
import "./input.css";
// import Header from './components/Header';
// import Herosec from './components/Herosec'
// import Services from './components/Services';
import { BrowserRouter } from "react-router-dom";
import Blockchain from "./components/Pages/Blockchain";
import DataScience from "./components/Pages/DataScience";
import AI from "./components/Pages/AI";
import Consulting from "./components/Pages/Consulting";
import SoftwareDev from "./components/Pages/SoftwareDev";
import Contact from "./components/Pages/Contact";
import Intro from "./components/Pages/Intro";
import Careers from "./components/Careers";

import { Routes, Route } from "react-router-dom";
import Blockeng from "./components/jobs/Blockeng";
import Blogs from "./components/Pages/Blogs";
import AboutUs from "./components/AboutUs";
import ScrollToTop from "./components/ScrollToTopPage";
import Privacy from "./components/Pages/Privacy";
import CookiePolicy from "./components/CookiePolicy";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <CookiePolicy />
      <Routes>
        <Route path="/blockchain" element={<Blockchain />} />
        <Route path="/datascience" element={<DataScience />} />
        <Route path="/softwaredevelopment" element={<SoftwareDev />} />
        <Route path="/ai" element={<AI />} />
        <Route path="/consulting" element={<Consulting />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/blockeng/:id" element={<Blockeng />} />
        <Route path="https://blog.syxlabs.com/" element={<Blogs />} />
        <Route path="/industry" element={<Intro />} />
        <Route path="/industry" element={<Intro />} />
        <Route path="/" element={<Intro />} exact />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
