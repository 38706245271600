import React from "react";

import Header from "../Header";

import Footer from "../Footer";

const Privacy = () => {
  const scrollToSection = (event, sectionId) => {
    event.preventDefault();
    const section = document.getElementById(sectionId);
    const offsetTop = section.offsetTop - 100; // Adjust the offset value as needed
    window.scrollTo({ top: offsetTop, behavior: "smooth" });
  };

  return (
    <div>
      <Header />
      <div className="flex flex-col sm:flex-row sm:justify-between bg-[#F9F9F9] h-[100%] pt-32 pb-20 px-[20px] sm:pt-36 sm:pl-32 sm:pr-64 sm:pb-40">
        <div className="flex flex-col gap-10">
          <div className="">
            <p className="sm:font-[400] font-[200] text-[32px] sm:text-[40px] max-w-xs sm:max-w-2xl">
              SYXLABS PRIVACY POLICY
            </p>
            <p className="text-[14px] sm:text-[16px] font-[300] max-w-[22.5rem] sm:max-w-[40.3rem] mt-10 leading-[21px] sm:leading-[24px]">
              SYX LABS (‘we,’ ‘us,’ ‘our,’ or ‘the Company’) value client data
              and takes all the necessary care and measures to ensure its
              safety. We aim to protect the privacy of our users, clients, and
              any other visitors (collectively referred to as ‘users’) of the
              Company’s website (‘our website’ or ‘this website’). This privacy
              policy aims to assure the safety of data for users visiting our
              website. This is intended to reinforce our commitment towards
              collecting and using client data with utmost transparency, without
              compromising their privacy. The privacy policy given hereunder
              applies only to the content/products/services available on this
              website, and the Company shall not be liable for safeguarding data
              submitted to any other website, which may contain its explicit
              privacy policies or data protection guidelines.
            </p>
          </div>

          <div id="dataCollection">
            <p className="font-bold text-[20px] sm:text-[24px]">
              Data Collection
            </p>
            <div className="sm:text-[16px] text-[14px] max-w-[23rem]  sm:max-w-[40rem] mt-6">
              <p className="font-[500]">Data Collected Through This Website </p>
              <p className="font-[300]">
                Our website may collect information through registration,
                surveys, and/or other data collection methods wherein the users
                are required to submit personal information, including • Contact
                details including name, address, contact number, email address,
                or other such details. • Other personal information, including
                date of birth may be submitted by users on the website.
              </p>
            </div>
            <div className="sm:text-[16px] text-[14px] max-w-[22rem] mt-12  sm:max-w-[40.1rem]">
              <p className="font-[500]">
                Additional Data Collected By This Website
              </p>
              <p className="font-[300]">
                The servers automatically detect users’ domain names and IP
                addresses on our websites, which does not consist of users’
                personal information. The site may also save anonymous “traffic
                data,” which does not comprise personally identifiable data and
                is referred solely for marketing purposes and to enhance our
                services. This website utilizes cookies to collect non-personal
                information pertaining to track the activities of users visiting
                the site. The site gathers data to aid us in both managing and
                extending efficient web services. The cookies are specifically
                used to store users’ accessibility preferences, along with
                texts. These cookies also help us track visitor activity on our
                website. They also help us analyze visitors’ search patterns to
                better usability and navigation
              </p>
            </div>
          </div>

          <div id="security">
            <p className="font-bold text-[20px] sm:text-[24px]">Security</p>
            <p className="sm:text-[16px] text-[14px] max-w-[22rem] mt-6 sm:max-w-[40rem] font-[300]">
              Information shared throughout the website are secured via
              encryption. It uses security measures to protect from any misuse,
              modification, or loss of information submitted by users; however,
              we cannot guarantee users of protection of data from any loss
              occurring due to any such misuse, modification, or loss. 3. DATA
              PROTECTION How Does The Website Utilize The Data Collected?
              Personal Information: We may collect and use personal information
              provided by users for the following purposes: a. To personalize
              the user experience and improve customer service: We process your
              personal information when you contact us in order to provide
              support with respect to questions, disputes, complaints,
              troubleshoot problems, etc. b. To process transactions: We may use
              the information users provide about
            </p>
          </div>

          <div id="dataProtection">
            <p className="font-bold text-[20px] sm:text-[24px]">
              Data Protection
            </p>
            <div className="space-y-6 mt-6 max-w-[40rem]">
              <p className="sm:text-16px text-[14px] font-[500]">
                How Does The Website Utilize The Data Collected?{" "}
              </p>
              <p className="sm:text-[16px] text-[14px] font-[300]">
                <span className="font-[500]">Personal Information:</span> We may
                collect and use personal information provided by users for the
                following purposes:
              </p>
              <p className="font-[300] sm:text-[16px] text-[14px]">
                a. To personalize the user experience and improve customer
                service: We process your personal information when you contact
                us in order to provide support with respect to questions,
                disputes, complaints, troubleshoot problems, etc.
              </p>
              <p className="font-[300] sm:text-[16px] text-[14px]">
                b. To process transactions: We may use the information users
                provide about themselves to process transactions. We use your
                personal information to communicate with you in relation to Our
                Services on administrative or account-related information. We
                will communicate with you to keep you updated about our Services
                for example, to inform you of relevant security issues, updates,
                or provide other transaction-related information. Without such
                communications, you may not be aware of important developments
                relating to your account that may affect how you can use our
                services. You may not optout of receiving critical service
                communications, such as emails or mobile notifications sent for
                legal or security purposes.
              </p>
              <p className="font-[300] sm:text-[16px] text-[14px]">
                c. To send periodic emails: We may use the email address
                provided by users to send them information and updates related
                to their transactions, account status, or to respond to
                inquiries, questions, or other requests.
              </p>
              <p className="font-[300] sm:text-[16px] text-[14px] max-w-xs">
                We may also use non-personal identification information
                collected from users to improve our Website, analyse user
                behaviour, and gather demographic information for internal
                purposes.
              </p>
            </div>
          </div>

          <div id="disclosure">
            <p className="font-bold text-[20px] sm:text-[24px]">
              Disclosure or Sharing of personal information
            </p>
            <div className="leading-[24px] mt-6 font-[300]">
              <p className="sm:text-[16px] text-[14px] max-w-[40rem] ">
                We have established stringent policies against selling or
                sharing of users’ personal information with external parties for
                purposes of branding, promotional, or other illicit uses
                governed by relevant identity/data theft regulations. If it is
                required by law or if the Company, in good faith, believes that
                it needs to share personal information for;
              </p>
              <p className="sm:text-[16px] text-[14px]">
                a. compliance to all or any legal provisions governing the
                Company
              </p>
              <p className="sm:text-[16px] text-[14px]">
                b. safeguarding the rights or property of the Company or users
                of this website
              </p>
              <p className="sm:text-[16px] text-[14px]">
                c. ensuring the safety of the public or users of this website.
              </p>
              <div className="mt-6 space-y-6">
                <div className="max-w-[40rem] sm:text-[16px] text-[14px]">
                  <p className="font-[500]">Anonymous Data </p>
                  <p>
                    We may utilize anonymous information collected on our
                    website for purposes of analyzing our Site traffic; however,
                    we do not review the data accumulated for users’ personally
                    identifiable details. Additionally, we may use anonymous IP
                    addresses to identify any problems with our servers or
                    improve the overall operation of the website.
                  </p>
                </div>
                <div className="max-w-[40rem] sm:text-[16px] text-[14px]">
                  <p className="font-[500]">
                    Personal Information Collected Through This Website{" "}
                  </p>
                  <p>
                    The personal information of users is collected in several
                    instances. When users wish to purchase any of our services,
                    we collect users’ payment information; however, we only
                    store this information up until it is provided to the
                    payment card processor, and not beyond that. When users
                    register on our website, purchase any products or services
                    listed on the website, request any information, or visit the
                    site for any other purposes, they submit data such as name,
                    contact number, email address.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div id="storage">
            <p className="font-bold text-[20px] sm:text-[24px]">
              Storage and Use
            </p>
            <div className="sm:text-[16px] text-[14px] mt-6 space-y-6 max-w-[40.8rem] font-[300]">
              <p>
                We do not share users’ personal information with third parties
                unless explicitly consented to by users and if it is necessary
                to offer the user with the services available on the website or
                in compliance with law. The personal details submitted on our
                website by the user is employed to complete the sale of
                products, to notify users of any updates associated with such
                purchased products, and to inform them of products/services we
                consider may be of interest to them. We may use users’ personal
                information for verifying their identity or their
                qualifications, or in case of a follow up with transactions that
                have been initiated by them on the website. We may also use
                users’ contact details to notify them of any modifications to
                the website or to forward any other data about the Company.
              </p>
              <p>
                We may employ anonymous data collected through this website to
                analyze our Site traffic; however, we do not inspect the data
                for users’ personally identifiable details. We may also refer to
                anonymous IP addresses to recognize problems with our servers or
                improve the overall functioning of the website and enhance user
                experience. The site may also use cookies that allow the storage
                of a certain amount of information pertaining to your visit to
                our website on your device. We do not save any personally
                identifiable data in the cookies and do not collect any
                information on the website except for that collected by these
                cookies. Cookies allow us to improve user experience on our
                site. The user can choose to either accept or reject cookies via
                settings on the browser. The user’s information will not be
                stored unless they purchase a product or a service, or they
                submit a form that includes their personal details and will be
                stored and utilized for all intended purposes.
              </p>
            </div>
          </div>

          <div id="amendments" className="max-w-[41.1rem]">
            <p className="text-[20px] sm:text-[24px] font-bold">
              Amendents in the privacy policy
            </p>
            <p className="sm:text-[16px] text-[14px] mt-6 font-[300]">
              We reserve all rights to add to, amend, update, or alter our
              privacy policy at any given time by posting about such additions,
              amendments, updates, or alterations, and are not liable to provide
              any notice to users. Any such additions, amendments, updates, or
              alterations will come into effect as soon as it is posted on the
              site. If we amend our privacy policy, the revisions would be
              posted to the privacy statement of the company; therefore, we
              expect users to frequently review clauses. We ensure that all our
              users are aware of the type of data we request, and when we
              consider it appropriate to use or share it.
            </p>
          </div>

          <div id="contactus" className="max-w-[40rem]">
            <p className="text-[20px] sm:text-[24px] font-bold">Contact Us</p>
            <p className="sm:text-[16px] text-[14px] mt-6 font-[300]">
              For any queries about the Privacy Policy, our practices related to
              the website, or for altering or removing your data from our
              database, please contact us at https://syxlabs.com/contact
            </p>
          </div>

          <div id="acceptance" className="max-w-[40rem]">
            <p className="text-[20px] sm:text-[24px] font-bold">
              Acceptance of Terms
            </p>
            <p className="sm:text-[16px] text-[14px] mt-6 font-[300]">
              By using this Website, you signify your acceptance of this Privacy
              Policy. If you do not agree to this Privacy Policy, please do not
              use our Platform. Your continued use of the Website following the
              posting of changes to this Privacy Policy will be deemed your
              acceptance of those changes.
            </p>
          </div>
        </div>

        <div className=" flex flex-col hidden sm:block">
          <div>
            <p className="font-[500] text-[14px]">Last Updated</p>
            <p className="text-[12px] text-[#474545] mt-6 font-[300]">
              23rd May 2023
            </p>
          </div>
          <div className="mt-16 ">
            <p className="font-[500] text-[14px]">Topics</p>
            <div className="mt-10 space-y-6">
              <div className="w-[98px] h-[32px] border-b-[1px] border-[#989898] pt-2">
                <a
                  href="#dataCollection"
                  className="text-[12px]"
                  onClick={(e) => scrollToSection(e, "dataCollection")}
                >
                  Data Collection
                </a>
              </div>
              <div className="w-[60px] h-[32px] border-b-[1px] border-[#989898] pt-2">
                <a
                  href="#security"
                  className="text-[12px]"
                  onClick={(e) => scrollToSection(e, "security")}
                >
                  Security
                </a>
              </div>
              <div className="w-[100px] h-[32px] border-b-[1px] border-[#989898] pt-2">
                <a
                  href="#dataProtection"
                  className="text-[12px]"
                  onClick={(e) => scrollToSection(e, "dataProtection")}
                >
                  Data Protection
                </a>
              </div>
              <div className="w-[160px] h-[50px] border-b-[1px] border-[#989898] pt-[1px]">
                <a
                  href="#disclosure"
                  className="text-[12px] mr-10"
                  onClick={(e) => scrollToSection(e, "disclosure")}
                >
                  Disclosure or sharing of information
                </a>
              </div>
              <div className="w-[104px] h-[32px] border-b-[1px] border-[#989898] pt-2">
                <a
                  href="#storage"
                  className="text-[12px]"
                  onClick={(e) => scrollToSection(e, "storage")}
                >
                  Storage and Use
                </a>
              </div>
              <div className="w-[160px] h-[32px] border-b-[1px] border-[#989898] pt-2">
                <a
                  href="#amendments"
                  className="text-[12px]"
                  onClick={(e) => scrollToSection(e, "amendments")}
                >
                  Amendents in privacy policy
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Privacy;
