const SyxSociety = () => {
  return (
    <section className="w-full p-4 lg:p-16 bg-[#C5D5EA]">
      <div className="w-full p-4 lg:p-16 bg-[#D0E1F5]">
        <div className="w-full p-4 lg:p-16 bg-[#E5F1FF]">
          <div className="w-full px-[12px] py-[2rem] lg:p-[2.8rem] bg-[#F2F8FF] flex flex-col text-center justify-center items-center gap-6">
            <p className="text-black text-xl font-semibold uppercase">
              Become a Syxer
            </p>
            <p className="text-black text-[2rem] lg:text-[4rem]">
              Join Syx Society
            </p>
            <p className="max-w-md text-black text-sm md:text-xl">
              We have an amazing community and we would love for you to join us.
              Connect with fellow technology enthusiasts and be in the know of
              emerging and innovative technology
            </p>
            <a
              href="https://lnk.bio/go?d=https%3A%2F%2Fdiscord.gg%2FsvZDCZwuzA&hash=c023d003871439b37287bf87a0072360&id=4992003&ext=-1633351&timezone=America%2FNew_York&type=1"
              className="bg-[#CEFF64] text-[#0E0E0E] text-[0.875rem] px-4 py-2 flex justify-center items-center gap-1 mt-8"
              target="_blank"
              rel="noreferrer noopener"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M19.5088 5.34C18.1709 4.714 16.7402 4.25905 15.2446 4C15.0609 4.33209 14.8463 4.77877 14.6983 5.1341C13.1084 4.89499 11.5331 4.89499 9.97243 5.1341C9.8245 4.77877 9.60503 4.33209 9.41971 4C7.92243 4.25905 6.49018 4.71567 5.15222 5.34331C2.45355 9.42136 1.72199 13.3981 2.08777 17.3184C3.87767 18.655 5.6123 19.467 7.31766 19.9984C7.73872 19.4189 8.11425 18.8028 8.43776 18.1536C7.82162 17.9195 7.23149 17.6306 6.67389 17.2952C6.82182 17.1856 6.96652 17.071 7.10632 16.9531C10.5073 18.5438 14.2025 18.5438 17.5628 16.9531C17.7043 17.071 17.849 17.1856 17.9953 17.2952C17.436 17.6322 16.8443 17.9211 16.2281 18.1553C16.5517 18.8028 16.9256 19.4205 17.3482 20C19.0552 19.4687 20.7915 18.6567 22.5814 17.3184C23.0106 12.7738 21.8482 8.83355 19.5088 5.34ZM8.90109 14.9075C7.88016 14.9075 7.04291 13.9543 7.04291 12.7937C7.04291 11.6331 7.86228 10.6783 8.90109 10.6783C9.93993 10.6783 10.7772 11.6314 10.7593 12.7937C10.7609 13.9543 9.93993 14.9075 8.90109 14.9075ZM15.7681 14.9075C14.7471 14.9075 13.9099 13.9543 13.9099 12.7937C13.9099 11.6331 14.7292 10.6783 15.7681 10.6783C16.8069 10.6783 17.6441 11.6314 17.6263 12.7937C17.6263 13.9543 16.8069 14.9075 15.7681 14.9075Z"
                  fill="#121212"
                />
              </svg>
              Join our Discord
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SyxSociety;
