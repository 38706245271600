import React, { useState } from "react";
import Footer from "../Footer";
import Header from "../Header";

import axios from "axios";

import select from "../../assets/selectdown.svg";

const Contact = () => {
  const [formValues, setFormValues] = useState({
    companyName: "",
    firstName: "",
    lastName: "",
    country: "",
    phoneNo: "",
    workEmail: "",
    service: "",
    moreInfo: "",
    receiveInsights: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // https://{hostname}/v1/contactus

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // handle form submission logic here
  //   console.log("Form submitted:", formValues);
  //   //  form validation and API calls here
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios
      .post(
        "https://syx-server-747dfeee5139.herokuapp.com/v1/contactus",
        formValues
      )
      .then(() => {
        setIsSubmitted(true);
        setFormValues({
          companyName: "",
          firstName: "",
          lastName: "",
          country: "",
          phoneNo: "",
          workEmail: "",
          service: "",
          moreInfo: "",
          receiveInsights: false,
        });
      })
      .catch((error) => {
        console.error("API error:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      <Header />
      <div className="flex flex-col sm:bg-[#F9F9F9]">
        <div className="flex sm:flex-row flex-col justify-between mb-40">
          <div className="flex flex-col lg:ml-32 bg-[#F9F9F9]">
            <div className="border-[1px] border-black w-24 sm:w-[103px] sm:h-[36px] h-8 ml-4 sm:mt-44 mt-36  rounded-full flex items-center justify-center">
              <p className="font-light text-xs">CONTACT US</p>
            </div>
            <div className="flex flex-col mt-8 sm:mt-16 h-72 pl-4">
              <p className="font-[300] text-3xl sm:text-[64px]">Get in touch</p>
              <p className=" text-[16px] mt-10 sm:mt-12 sm:max-w-md max-w-xs">
                Have a specific question or want to speak directly with someone
                on the team?
                <br></br>
                <br></br>
                Please provide your information and someone from our team will
                respond shortly.
              </p>
            </div>
          </div>

          <div className="flex bg-white sm:w-[563px] pb-32 w-full sm:mr-32 sm:h-[830px] px-5  py-6 sm:pb-10 sm:pt-7 sm:mt-48 flex-col">
            <form onSubmit={handleSubmit}>
              <form className="flex flex-col space-y-3">
                <p className="text-black sm:text-[14px] text-xs after:content-['*'] after:ml-0.5 after:text-red-500">
                  Company Name
                </p>
                <input
                  type="text"
                  name="companyName"
                  value={formValues.companyName}
                  onChange={handleChange}
                  placeholder="Company Name"
                  className="px-6 py-2 border-[1px] border-gray-200 w-[343px] h-[48px] sm:w-[515px] font-light sm:h-[40px] text-[14px] focus:outline-none"
                />
              </form>

              <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-5 sm:space-y-0 mt-8">
                <div className="flex flex-col space-y-3">
                  <p className="text-black sm:text-[14px] text-xs after:content-['*'] after:ml-0.5 after:text-red-500">
                    First Name
                  </p>
                  <input
                    type="text"
                    name="firstName"
                    value={formValues.firstName}
                    onChange={handleChange}
                    placeholder="First Name"
                    className="px-6 py-2 w-[343px] h-[48px] sm:w-[250px] sm:h-[40px] border-[1px] border-gray-200 text-[14px] focus:outline-none font-light"
                  />
                </div>
                <div className="flex flex-col space-y-3">
                  <p className="text-black sm:text-[14px] text-xs after:content-['*'] after:ml-0.5 after:text-red-500">
                    Last Name
                  </p>
                  <input
                    type="text"
                    name="lastName"
                    value={formValues.lastName}
                    onChange={handleChange}
                    placeholder="Last Name"
                    className="px-6 py-2 w-[343px] h-[48px] sm:w-[250px] sm:h-[40px] border-[1px] border-gray-200 text-[14px] focus:outline-none font-light"
                  />
                </div>
              </div>

              <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-5 sm:space-y-0 mt-8">
                <div className="flex flex-col space-y-3 ">
                  <p className="text-black sm:text-[14px] text-xs after:content-['*'] after:ml-0.5 after:text-red-500">
                    Country
                  </p>
                  <input
                    type="text"
                    name="country"
                    value={formValues.country}
                    onChange={handleChange}
                    placeholder="Country/Region"
                    className="px-6 py-2 w-[343px] h-[48px] sm:w-[250px] sm:h-[40px] border-[1px] border-gray-200 text-[14px] focus:outline-none font-light"
                  />
                </div>
                <div className="flex flex-col space-y-3">
                  <p className="text-black sm:text-[14px] text-xs after:content-['*'] after:ml-0.5 after:text-red-500">
                    Phone
                  </p>
                  <input
                    type="tel"
                    name="phoneNo"
                    value={formValues.phoneNo}
                    onChange={handleChange}
                    placeholder="Phone"
                    className="px-6 py-2 w-[343px] h-[48px] sm:w-[250px] sm:h-[40px] border-[1px] border-gray-200 text-[14px] focus:outline-none font-light"
                  />
                </div>
              </div>

              <div className="flex flex-col space-y-3 mt-8">
                <p className="text-black sm:text-[14px] text-xs after:content-['*'] after:ml-0.5 after:text-red-500">
                  Work Email
                </p>
                <input
                  type="email"
                  name="workEmail"
                  value={formValues.workEmail}
                  onChange={handleChange}
                  placeholder="Email"
                  className="px-6 py-2 border-[1px] sm:w-[515px] sm:h-[40px] w-[343px] h-[48px] text-black border-gray-200 text-[14px] focus:outline-none font-light"
                />
              </div>

              <div className="flex flex-col space-y-3 mt-8 relative">
                <p className="text-black sm:text-[14px] text-xs after:content-['*'] after:ml-0.5 after:text-red-500">
                  Service
                </p>
                <select
                  type="text"
                  name="service"
                  value={formValues.service}
                  onChange={handleChange}
                  placeholder="Select services"
                  className="appearance-none px-6 py-2 sm:w-[515px] sm:h-[40px] text-[#989898] w-[343px] h-[48px] border-[1px] border-gray-200 text-[14px] focus:outline-none font-light"
                >
                  <option className="">Select Services</option>
                  <option>Blockchain Software Development</option>
                  <option>Insightful Data Science & Analysis</option>
                  <option>Customised AI Algorithms</option>
                  <option>Software Development</option>
                  <option>Consulting & Advisory</option>
                </select>
                <div className="pointer-events-none absolute inset-y-[42px] sm:inset-y-[37px] right-0 sm:right-7 flex items-center px-2 text-gray-700">
                  <img src={select} alt="" />
                </div>
              </div>

              <div className="flex flex-col space-y-3 mt-8">
                <p className="text-black sm:text-[14px] text-xs after:content-['*'] after:ml-0.5 after:text-red-500">
                  Tell us more about your case
                </p>
                <textarea
                  type="message"
                  rows="5"
                  cols="50"
                  name="moreInfo"
                  value={formValues.moreInfo}
                  onChange={handleChange}
                  className="px-6 w-[343px] h-[120px] sm:w-[515px] sm:h-[120px] py-4 border-[1px] border-gray-200 focus:outline-none text-[14px] font-[400]"
                />
              </div>

              <div className="flex flex-row mt-5 space-x-2">
                <input
                  type="checkbox"
                  name="receiveInsights"
                  checked={formValues.receiveInsights}
                  onChange={handleChange}
                  className="flex accent-[#A6F200] justfiy-start mt-1 w-4 h-4 bg-transparent border-[1px] rounded-sm border-black"
                />
                <p className="text-black text-[14px] font-light ">
                  I want to receive SyxthSense Insights to my email.
                </p>
              </div>

              <button
                type="submit"
                className=" bg-[#A6F200] text-[#003249] w-[126px] h-[40px] mt-8 text-[14px] hover:bg-black hover:text-[#A6F200]"
              >
                {isLoading
                  ? "Loading..."
                  : isSubmitted
                  ? "Submitted!"
                  : "Submit Inquiry"}
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
