import React from "react";
import chart from "../assets/chart.svg";
import trisvg1 from "../assets/trisvg1.svg";

import trisvg1Mobile from "../assets/tri1mobile.svg";

import trisvg2Mobile from "../assets/trimobile2.svg";

import trisvg2 from "../assets/trisvg2.svg";

import tri2Mobile from "../assets/tri2mobile.svg";

import servers from "../assets/servers.svg";
import twirl from "../assets/twirl.svg";
import { motion } from "framer-motion";
import hero from "../assets/Hero.png";
import { styles } from "../styles";
import { Link } from "react-router-dom";

const Herosec = () => {
  return (
    <section
      className="w-full text-[#474545] "
      style={{
        backgroundImage: `url(${hero})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      id="herosec"
    >
      <div className="flex relative text-[#474545] justify-center  h-[900px] md:h-screen">
        <motion.img
          src={twirl}
          alt="icon"
          className="lg:flex absolute left-[4rem] lg:left-[9vw] top-28 lg:w-auto w-[112.87px] lg:h-auto h-[83.8px] hidden"
          initial={{ opacity: 0, x: 0 }}
          animate={{ opacity: 1, x: -100, duration: 2000 }}
          transition={{ delay: 1 }}
        />

        <motion.img
          src={trisvg1Mobile}
          alt="icon"
          className="flex absolute left-[6rem] lg:left-56 top-28 lg:hidden  "
          initial={{ opacity: 0, x: 0 }}
          animate={{ opacity: 1, x: -100, duration: 2000 }}
          transition={{ delay: 1 }}
        />
        <motion.img
          src={trisvg1}
          alt="icon"
          className=" absolute lg:left-36 bottom-72 hidden lg:flex"
          initial={{ opacity: 0, y: 0 }}
          animate={{ opacity: 1, y: 100, duration: 2000 }}
          transition={{ delay: 1 }}
        />

        <motion.img
          src={tri2Mobile}
          alt="icon"
          className=" absolute lg:left-36 bottom-72 left-[-0.5rem] lg:hidden flex"
          initial={{ opacity: 0, y: 0 }}
          animate={{ opacity: 1, y: 100, duration: 2000 }}
          transition={{ delay: 1 }}
        />
        <motion.img
          src={trisvg2}
          alt="icon"
          className="absolute right-44 top-28 lg:flex hidden "
          initial={{ opacity: 0, x: 0 }}
          animate={{ opacity: 1, x: 100, duration: 2000 }}
          transition={{ delay: 1 }}
        />

        <motion.img
          src={trisvg2Mobile}
          alt="icon"
          className=" absolute right-[6.5rem] top-28 lg:hidden flex  "
          initial={{ opacity: 0, x: 0 }}
          animate={{ opacity: 1, x: 100, duration: 2000 }}
          transition={{ delay: 1 }}
        />
        <motion.img
          src={chart}
          alt="icon"
          className=" absolute right-28 bottom-80 lg:flex hidden"
          initial={{ opacity: 0, y: 0 }}
          animate={{ opacity: 1, y: 100 }}
          transition={{ delay: 1 }}
        />
        <motion.img
          src={servers}
          alt=""
          className="flex absolute right-[0%] lg:right-[50%] lg:left-[44.5%] lg:h-[auto] h-[240px] lg:w-[auto] w-[223px] bottom-0"
          initial={{ opacity: 0, x: 0 }}
          animate={{ opacity: 1, x: -100 }}
          transition={{ delay: 1 }}
        />
        <div className="flex absolute mt-48 flex-col items-center text-[#474545]">
          <motion.h1
            className={`${styles.heroHeadText} lg:block hidden`}
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 1 }}
          >
            Make ideas real.
          </motion.h1>

          {/* show on mobile */}
          <motion.h1
            className={`${styles.heroHeadText} lg:hidden leading-snug`}
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 1 }}
          >
            Make ideas
            <br />
            real.
          </motion.h1>
          <motion.p
            className={`${styles.heroSubText} mt-[24px] pl-[18px] pr-[14px] lg:w-[50rem] w-full `}
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1 }}
          >
            Empower your team and business with our cutting-edge technology,
            dedicated expertise, and actionable insights.
          </motion.p>
          {/*
          <div className=" mt-[56px]">
            <motion.button
              className="flex  justify-center items-center flex-col w-[197px] text-sm  text-white bg-primary h-[42px] py-[16px] px-[12px] hover:bg-[#A6F200] cursor-pointer"
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1 }}
            >
              <p className="font-light mb-1 text-md">
                
                
                <Link to="/contact">Send a message</Link>
                
          
                
                
                </p>
            </motion.button>
          </div>
					*/}
        </div>
      </div>
    </section>
  );
};
export default Herosec;

// // <div className='flex flex-col'>
// <img src={group2} alt="" />
// </div>
//           <div className='flex flex-row'>
