import React from "react";

const Ssolutions = () => {
  return (
    <div className=" w-full  lg:px-[146px] px-[16px] lg:pt-[96px] pt-[80px] ">
      <div className="flex flex-col">
        <p className="font-light text-primary lg:text-[40px] text-[32px]">
          Our Solutions
        </p>
        <div className="flex flex-col  pb-10 justify-evenly lg:mt-[96px] mt-[48px] ">
          <div className="flex flex-col sm:flex-row space-x-0 lg:space-x-16 ">
            <div className="flex flex-col">
              <div className="flex gap-3 mb-12">
                <span className=" w-2 mt-[6px] h-2 bg-[#A6F200] rounded-full"></span>
                <div>
                  <h2 className="font-medium text-base lg:text-lg max-w-xs sm:max-w-[17rem] text-primary ">
                    Web application development
                  </h2>
                  <p className="font-light text-[14px]  max-w-xs pt-3 text-secondary">
                  We create responsive and user-friendly web applications that cater to your specific business needs, delivering a seamless digital experience for your users.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col mb-12">
              <div className="flex gap-3">
                <span className=" w-2 mt-[6px] h-2 bg-[#A6F200] rounded-full"></span>
                <div>
                  <h2 className="font-medium text-base lg:text-lg max-w-xs sm:max-w-[17rem] text-primary ">
                   Mobile application development
                  </h2>
                  <p className="font-light text-[14px]  max-w-xs pt-3 text-secondary">
                  Build exceptional mobile apps for iOS and Android platforms that engage users, enhance brand presence, and offer intuitive functionality to meet your business objectives.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex items-start gap-3">
                <span className=" w-2 mt-[6px] h-2 bg-[#A6F200] rounded-full"></span>
                <div>
                  <h2 className="font-medium text-base lg:text-lg max-w-xs sm:max-w-[17rem] text-primary ">
                  Cloud computing & infrastructure
                  </h2>
                  <p className="font-light text-[14px]  max-w-xs pt-3 text-secondary">
                  Leverage the power of cloud technology with our expertise in designing and implementing scalable and secure cloud infrastructure solutions, ensuring seamless data management and accessibility.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row  space-x-0 lg:space-x-16 ">
            <div className="flex flex-col mt-6">
              <div className="flex items-start gap-3">
                <span className=" w-2 mt-[6px] h-2 bg-[#A6F200] rounded-full"></span>
                <div>
                  <h2 className="font-medium text-base lg:text-lg max-w-xs sm:max-w-[17rem] text-primary ">
                  Enterprise software development
                  </h2>
                  <p className="font-light text-[14px]  max-w-xs pt-3 text-secondary">
                  Tailor-made enterprise software solutions that empower your organisation with optimised workflows, enhanced productivity, and seamless integration across departments for better business performance.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col mt-10 sm:mt-5">
              <div className="flex items-start gap-3">
                <span className=" w-2 mt-[6px] h-2 bg-[#A6F200] rounded-full"></span>
                <div>
                  <h2 className="font-medium text-base lg:text-lg  text-primary ">
                  DevOps & automation
                  </h2>
                  <p className="font-light text-[14px]  max-w-xs pt-3 text-secondary">
                  Streamline your software development process and enhance efficiency with our DevOps and automation services, enabling continuous integration, delivery, and deployment for faster time-to-market.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ssolutions;
