import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { v4 } from "uuid";
import hero from "../assets/Hero.png";
import marks from "../assets/marks.svg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { FADE_UP_ANIMATION_VARIANTS } from "../constants/fmvariants";
import moore from "../assets/moore.svg";
import amaku from "../assets/amaku.svg";
import adeoti from "../assets/adeoti.svg";
import naomi from "../assets/naomi.svg";

import axios from "axios";

const employees = [
  {
    id: 1,
    name: "Moore Dagogo-Hart",
    department: "Founder & CEO",
    image: moore,
  },
  {
    id: 2,
    name: "David Amaku",
    department: "Lead Blockchain Engineer",
    image: amaku,
  },
  {
    id: 3,
    name: "Adeoti Salami",
    department: "Lead Product Designer",
    image: adeoti,
  },
  {
    id: 4,
    name: "Naomi Izevbekhai",
    department: "Writer",
    image: naomi,
  },
];

const Work = ({ name, department, image }) => {
  return (
    <div className="flex justify-start items-center flex-col px-[5px] sm:mr-[0px] mr-[10px] w-[147px] sm:w-[100%] py-3">
      {image ? (
        <img
          className="w-[147px] h-[148px] sm:h-[200px] sm:w-[216px] "
          src={image}
          alt={name}
        />
      ) : (
        <div
          className="w-[147px] h-[148px] sm:h-[200px] sm:w-[216px] bg-[#ECECEC]"
          style={{ border: "0px solid #ccc" }}
        ></div>
      )}
      <div className="flex flex-col mt-5 space-y-[1px]">
        <p className="text-[16px] text-[#0E0E0E] font-[400]">{name}</p>
        <p className="text-[14px] font-[400] xl:text-[#7C9299] sm:text-[16px] text-[#636568]">
          {department}
        </p>
      </div>
    </div>
  );
};
const AboutUs = () => {
  const handleClick = () => {
    window.location.href = "/careers#roles";
  };

  const [jobs, setJobs] = useState([]);

  const [, setIsFormVisible] = useState(false);

  const [, setFormJobId] = useState(null);

  const handleApplyClick = (jobId) => {
    setIsFormVisible(true);
    setFormJobId(jobId);
  };

  // const handleCloseClick = () => {
  //   setIsFormVisible(false);
  //   setFormJobId(null);
  // };

  // const [formValues, setFormValues] = useState({
  //   firstName: "",
  //   lastName: "",
  //   phone: "",
  //   email: "",
  //   letter: "",
  //   resume: null,
  // });

  //   const handleChange = (event) => {
  //     const { name, value, files } = event.target;
  //     setFormValues({
  //       ...formValues,
  //       [name]: value,
  //       resume: files && files[0],
  //     });

  //     if (files && files[0]) {
  //       const reader = new FileReader();
  //       reader.onload = () => {
  //         setThumbnail(reader.result);
  //       };
  //       reader.readAsDataURL(files[0]);
  //     } else {
  //       setThumbnail(""); // clear the thumbnail when no file is selected
  //     }
  //   };

  //   const handleSubmit = (event) => {
  //     event.preventDefault();
  //     // perform form submission logic here using formValues
  //     setIsFormVisible(false); // hide the form after submission
  //   };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://syx-server-747dfeee5139.herokuapp.com/v1/jobs"
        );
        // console.log(response.data); // Check the data format in the console
        setJobs(response.data.data);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const titleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <motion.div
      style={{ width: "100%" }}
      initial="hidden"
      animate="show"
      viewport={{ once: true }}
      variants={{
        hidden: {},
        show: {
          transition: {
            staggerChildren: 0.15,
          },
        },
      }}
    >
      <Header />
      <section className="w-full text-[#474545] flex flex-col overflow-x-hidden">
        <div
          className="relative text-[#474545] justify-center"
          style={{
            backgroundImage: `url(${hero})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <motion.div
            className="text-[#0E0E0E] mt-32 px-6  sm:px-32 py-24  sm:pb-52 w-full flex flex-col content-center"
            id="abtt"
            variants={FADE_UP_ANIMATION_VARIANTS}
          >
            <div className="w-[80px] h-[26px] sm:w-[90px] sm:h-[36px] rounded-full border-[1px] border-black items-center justify-center flex">
              <p className="text-[10px] sm:text-[12px] my-3 mx-2 font-[500]">
                ABOUT US
              </p>
            </div>
            <motion.div
              className="flex flex-col w-full mt-10 space-y-10"
              variants={FADE_UP_ANIMATION_VARIANTS}
            >
              <p className="text-[32px] sm:text-[64px] font-light text-[#0E0E0E]  leading-[48px] sm:leading-[76.8px] sm:max-w-3xl">
                We’re empowering you with the power of AI and blockchain
              </p>
              <p className="sm:text-[18px] text-[16px] font-normal leading-[27px] sm:max-w-lg">
                Our mission is to provide cutting-edge blockchain and AI
                solutions to unlock the full potential of individuals and
                businesses.
              </p>
            </motion.div>
          </motion.div>
        </div>

        <div className="flex flex-col sm:flex-row px-5 sm:px-32 py-20 justify-between">
          <div className="flex flex-col space-y-10 sm:space-y-20">
            <p className="text-4xl font-light">Our Values</p>
            <p className="sm:text-sm text-sm font-light max-w-[18rem] sm:max-w-xs">
              Our values define us – they guide our decisions, actions, and
              relationships. We hold ourselves to the highest standards and
              strive to continuously innovate and improve. <br></br> <br></br>{" "}
              Together, we create solutions that are adaptable, trustworthy, and
              impactful.
            </p>
          </div>

          <div className="flex flex-col xl:flex-row mt-12 sm:mt-28">
            <div className="flex flex-col sm:flex-col space-y-5 w-full sm:mr-5">
              <div className="flex flex-col bg-[#F9F9F9] w-[343px] sm:w-96 px-7 h-[140px] sm:h-36 py-5 space-y-4">
                <p className="sm:text-[18px] text-[16px] font-bold sm:mt-1">
                  Innovation
                </p>
                <p className="text-sm font-light max-w-xs">
                  We continuously strive to develop <br></br>groundbreaking
                  solutions that address real-world challenges.
                </p>
              </div>

              <div className="flex flex-col bg-[#F9F9F9] w-[343px] sm:w-96 px-7 h-[140px] sm:h-36 py-5 space-y-4">
                <p className="sm:text-[18px] text-[16px] font-bold sm:mt-1">
                  Excellence
                </p>
                <p className="text-sm font-light max-w-xs">
                  We are committed to delivering the highest quality products
                  and services to our clients.
                </p>
              </div>

              <div className="flex flex-col bg-[#F9F9F9] w-[343px] sm:w-96 px-7 h-[140px] sm:h-36 py-5 space-y-4">
                <p className="sm:text-[18px] text-[16px] font-bold sm:mt-1">
                  Adaptability
                </p>
                <p className="text-xs font-light max-w-xs">
                  We embrace change and constantly evolve to meet the dynamic
                  needs of our clients and the industry.
                </p>
              </div>
            </div>

            <div className="flex flex-col sm:flex-col mt-5 sm:mt-0 space-y-5">
              <div className="flex flex-col bg-[#F9F9F9] w-[343px] sm:w-96 px-7 h-[140px] sm:h-36 py-5 space-y-4 pb-10">
                <p className="sm:text-[18px] text-[16px] font-bold sm:mt-1">
                  Collaboration
                </p>
                <p className="text-xs font-light max-w-xs">
                  We foster a culture of teamwork and open communication to
                  achieve common goals
                </p>
              </div>
              <div className="flex flex-col bg-[#F9F9F9] w-[343px] sm:w-96 px-7 h-[140px] sm:h-36 py-5 space-y-4">
                <p className="sm:text-[18px] text-[16px] font-bold sm:mt-1">
                  Integrity
                </p>
                <p className="text-xs font-light max-w-xs">
                  We operate with transparency, honesty, and ethical principles
                  in every aspect of our business.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-32 bg-[#A6F200] flex flex-col items-center justify-center sm:w-[90%] mx-[5%] my-20">
          <div
            className="w-full flex flex-col justify-center items-center  xl:pl-[5%] xl:pr-[5%] min-h-[348px] sm:min-h-[489px] bg-[#F9F9F9] p-10"
            id="he"
          >
            <p className="text-[20px] text-center text-[#0E0E0E] xl:text-left sm:text-center sm:text-[40px] sm:font-[300] font-normal my-[20px]">
              The team
            </p>
            <div className="w-full max-w-[1400px] justify-center mx-auto grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] place-items-center my-[0px] p-[0]">
              {employees.map((work) => (
                <div className="w-full" key={v4()}>
                  <Work
                    name={work.name}
                    department={work.department}
                    image={work.image}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="mt-[40px] bg-[#A6F200] flex flex-col sm:w-[90%] mx-[5%] my-20 pt-[20px]">
        <div className="flex flex-col xl:flex-row justify-center xl:justify-between items-center md:px-20 sm:px-20 sm:py-10">
          <div className="flex flex-col w-[309px] sm:w-80 space-y-6 mt-2">
            <h1 className="text-[32px] sm:text-[48px] text-[#0E0E0E] font-[400]">
              Join our team
            </h1>
            <p className="text-[16px] text-[#0E0E0E] font-normal w-[309px] xl:w-[150%] leading-[24px]">
              We are always on the lookout for talented individuals who are
              passionate about blockchain and AI and want to help us build the
              future. <br></br>
              Join our team of experts and work on exciting projects that make a
              difference.
            </p>
          </div>
          <div className="flex flex-col justify-end items-end">
            <div className="flex flex-col space-y-5 mt-12 md:mt-6 justify-start items-start xl:justify-end xl:items-end">
              <div className="flex flex-col gap-4 mt-16 sm:mt-7">
                {jobs.slice(0, 2).map((job) => (
                  <div
                    className="flex flex-row w-[309px] h-[69px] sm:w-[395px] sm:h-[69px] bg-[#ECECEC] px-5 py-2 justify-between"
                    key={v4()}
                  >
                    <div className="flex flex-col gap-2">
                      <p className="font-[500] text-[16px]">
                        {titleCase(job.title)}
                      </p>
                      <p className="font-[400] text-[#7C9299] text-[14px]">
                        {titleCase(job.location)}
                      </p>
                    </div>
                    <Link to={`/blockeng/${job.id}`}>
                      <button
                        onClick={handleApplyClick}
                        className="border-[1px] mt-2 w-[84px] h-[33px] border-black"
                      >
                        <p className="font-light text-[14px]">Apply</p>
                      </button>
                    </Link>
                  </div>
                ))}
              </div>

              <button
                onClick={handleClick}
                className="w-[140px] xl:w-[176px] h-[40px] bg-[#003249]  mt-8 hover:bg-[#8ce43d] cursor-pointer "
              >
                <motion.div
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 1 }}
                  className="font-light text-xs text-white "
                >
                  See all open roles
                </motion.div>
              </button>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-row relative bottom-0 sm:mt-8">
          <img src={marks} alt="" className="w-full flex relative bottom-0" />
        </div>
      </section>
      <Footer />
    </motion.div>
  );
};
export default AboutUs;
