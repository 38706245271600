import React, { useState, useEffect } from "react";
import { v4 } from "uuid";
import Header from "./Header";
import axios from "axios";

import marks from "../assets/marks.png";
import marks2 from "../assets/marksmobile.svg";
import Footer from "./Footer";

import locationimg from "../assets/location.png";

import { Link } from "react-router-dom";

const Careers = () => {
  const [searchInput, setSearchInput] = useState("");
  const [suggestedJobs, setSuggestedJobs] = useState([]);
  const [jobs, setJobs] = useState([]);

  // const [items, setItems] = useState([]);
  // const [visible, setVisible] = useState(6);

  // const showMoreItems = () => {};

  // useEffect(() => {
  //   fetch("https://syx-server-747dfeee5139.herokuapp.com/v1/jobs")
  //     .then((res) => res.json)
  //     .then((data) => console.log(data));
  //   // .then((data) => setItems(data));
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://syx-server-747dfeee5139.herokuapp.com/v1/jobs"
        );
        // console.log(response.data); // Check the data format in the console
        setJobs(response.data.data);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSearchInputChange = (e) => {
    const input = e.target.value;
    setSearchInput(input);

    if (input.startsWith("b") || input.startsWith("bl")) {
      setSuggestedJobs(["blockchain"]);
    } else {
      setSuggestedJobs([]);
    }
  };

  const titleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <div>
      <Header />
      <section className="flex flex-col sm:flex sm:flex-col  mt-20 sm:mt-0 ">
        <div className="bg-[#003249] h-[590px] sm:h-[740px]">
          <div className="flex flex-col mx-4  lg:my-36 sm:mx-28 justify-evenly">
            <div className="border-[1px] border-white w-20 h-8 sm:w-[85px] sm:h-[36px] mt-14 sm:mt-2 lg:mb-10 rounded-full flex items-center justify-center">
              <p className="font-light text-xs text-white">CAREERS</p>
            </div>
            <div className="flex flex-col justify-evenly h-72 mt-8 sm:mt-8">
              <p className="font-[200] text-4xl text-white sm:text-6xl max-w-xl">
                Join us in making
              </p>
              <p className="font-[200] text-4xl sm:text-6xl max-w-xl text-[#A6F200] leading-[38px]">
                ideas real
              </p>
              <p className="font-light text-[16px] sm:max-w-lg max-w-[19rem] text-white mt-10 sm:mt-10">
                Looking for new opportunities in the world of blockchain and AI?{" "}
                <span className="hidden lg:block">
                  Join Syx Labs and be part of a dynamic team that's passionate
                  about creating innovative solutions.{" "}
                </span>
              </p>
              <p className="block sm:hidden font-light text-[16px] sm:max-w-md max-w-[19rem] text-white mt-10">
                Join Syx Labs and be part of a dynamic team that's passionate
                about creating innovative solutions.{" "}
              </p>
              <div className="sm:mr-32 mt-16 sm:mt-24 sm:relative lg:block hidden">
                <button
                  className="flex justify-center relative items-start items-center h-[40px] w-[152px] flex-col gap-4 text-[#003249] bg-[#A6F200] sm:h-[40px] sm:w-[152px] hover:bg-[#003249] hover:text-[#A6F200] cursor-pointer"
                  onClick={() => {
                    const element = document.getElementById("roles");
                    if (element) {
                      element.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }
                  }}
                >
                  <p className="text-[14px]">See open positions</p>
                </button>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-row absolute  hidden lg:block">
            <img src={marks} alt="" className="w-full flex absolute bottom-0" />
          </div>
          <div className=" block lg:hidden flex relative mt-4">
            <img src={marks2} alt="" className="w-full flex absolute top-16" />
            <button className="flex justify-center ml-4 relative mt-12 items-center h-[40px] w-[152px] flex-col gap-4 text-[#003249] bg-[#A6F200] sm:h-[40px] sm:w-[152px] hover:bg-[#003249] hover:text-[#A6F200] z-10 cursor-pointer">
              <p className="text-[14px]">See open positions</p>
            </button>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row px-5 sm:px-32 py-20 justify-between">
          <div className="flex flex-col space-y-10 sm:space-y-20">
            <p className="text-4xl font-light">Our Values</p>
            <p className="sm:text-sm text-[16px] font-light max-w-[21rem] sm:max-w-xs">
              Our values shape our identity, influencing how we make choices,
              interact with others, and carry out our work. We uphold the utmost
              standards, consistently seeking innovation and progress.
              <br></br> <br></br> Collaboratively, we develop solutions that are
              flexible, reliable, and make a meaningful difference.
            </p>
          </div>

          <div className="flex flex-col sm:flex-row mt-12 sm:mt-28">
            <div className="flex flex-col sm:flex-col space-y-5 w-full sm:mr-5">
              <div className="flex flex-col bg-[#F9F9F9] w-[343px] sm:w-96 px-7 h-[140px] sm:h-36 py-5 space-y-4">
                <p className="sm:text-[18px] text-[16px] font-bold sm:mt-1">
                  Innovation
                </p>
                <p className="text-sm font-[400] max-w-xs">
                  We continuously strive to develop <br></br>groundbreaking
                  solutions that address real-world challenges.
                </p>
              </div>

              <div className="flex flex-col bg-[#F9F9F9] w-[343px] sm:w-96 px-7 h-[140px] sm:h-36 py-5 space-y-4">
                <p className="sm:text-[18px] text-[16px] font-bold sm:mt-1">
                  Excellence
                </p>
                <p className="text-sm font-light max-w-xs">
                  We are committed to delivering the highest quality products
                  and services to our clients.
                </p>
              </div>

              <div className="flex flex-col bg-[#F9F9F9] w-[343px] sm:w-96 px-7 h-[140px] sm:h-36 py-5 space-y-4">
                <p className="sm:text-[18px] text-[16px] font-bold sm:mt-1">
                  Adaptability
                </p>
                <p className="text-xs font-light max-w-xs">
                  We embrace change and constantly evolve to meet the dynamic
                  needs of our clients and the industry.
                </p>
              </div>
            </div>

            <div className="flex flex-col sm:flex-col mt-5 sm:mt-0 space-y-5">
              <div className="flex flex-col bg-[#F9F9F9] w-[343px] sm:w-96 px-7 h-[140px] sm:h-36 py-5 space-y-4 pb-10">
                <p className="sm:text-[18px] text-[16px] font-bold sm:mt-1">
                  Collaboration
                </p>
                <p className="text-xs font-light max-w-xs">
                  We foster a culture of teamwork and open communication to
                  achieve common goals
                </p>
              </div>
              <div className="flex flex-col bg-[#F9F9F9] w-[343px] sm:w-96 px-7 h-[140px] sm:h-36 py-5 space-y-4">
                <p className="sm:text-[18px] text-[16px] font-bold sm:mt-1">
                  Integrity
                </p>
                <p className="text-xs font-light max-w-xs">
                  We operate with transparency, honesty, and ethical principles
                  in every aspect of our business.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className="bg-[#F9F9F9] flex flex-col px-5 sm:px-28 py-20"
          id="roles"
        >
          <div className="flex flex-col sm:flex-row sm:justify-between">
            <p className="text-4xl font-light">Open roles</p>
            <div className="flex flex-row sm:flex-col mt-6 sm:mt-0 gap-6 sm:gap-4">
              <input
                type="text"
                className="w-52 bg-transparent border-b-[0.5px] border-black focus:outline-none text-xs"
                value={searchInput}
                onChange={handleSearchInputChange}
              />

              <button
                type="submit"
                className="bg-[#003249] hover:bg-[#A6F200] hover:text-white w-[109px] h-[36px] sm:ml-[6.3rem] items-center"
              >
                <p className="text-xs text-white font-light">Search Jobs</p>
              </button>
            </div>
          </div>

          <div className="flex flex-col text-[#474545] gap-28  mt-12 sm:mt-28 sm:items-center pb-12">
            {jobs &&
              jobs
                .filter((job) =>
                  job.title.toLowerCase().includes(searchInput.toLowerCase())
                )
                .reduce((result, filteredJob, index) => {
                  if (index % 3 === 0) {
                    result.push([]);
                  }
                  result[Math.floor(index / 3)].push(filteredJob);
                  return result;
                }, [])
                .map((jobGroup) => (
                  <div
                    className="flex flex-col sm:flex-row space-y-5 sm:space-y-0 sm:space-x-7"
                    key={v4()}
                  >
                    {jobGroup.map((filteredJob) => (
                      <Link to={`/blockeng/${filteredJob.id}`} key={v4()}>
                        <div className="flex flex-col w-[343px] h-[120px] sm:w-96 sm:h-36 bg-white px-5 py-4 justify-between hover:bg-[#003249] hover:bg-[#7C9299] hover:text-black">
                          <p className="text-sm">
                            {titleCase(filteredJob.title)}
                          </p>
                          <div className="flex flex-row justify-between text-xs font-light">
                            <div className="flex flex-row space-x-2">
                              <img
                                src={locationimg}
                                alt=""
                                className="w-[11px] mt-[2px] h-[13px]"
                              />
                              <p className="text-[12px] sm:text-[10px]">
                                {titleCase(filteredJob.location)}
                              </p>
                            </div>
                            <p className="text-[12px] text-[10px] font-light">
                              {titleCase(filteredJob.category)}
                            </p>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                ))}
            {jobs.filter((job) =>
              job.title.toLowerCase().includes(searchInput.toLowerCase())
            ).length === 0 && (
              <div className="flex flex-col justify-center py-16  items-center">
                <p className="text-[#474545] text-[28px] font-[500] mt-16 sm:mt-0 text-center">
                  No roles available
                </p>
                <p className="sm:text-[18px] text-[16px] leading-[40px]">
                  There are currently no job openings for{" "}
                  <span className="font-bold">{searchInput}</span>
                </p>
              </div>
            )}

            {jobs.filter((job) =>
              job.title.toLowerCase().includes(searchInput.toLowerCase())
            ).length > 0 && (
              <div className="w-full flex justify-center">
                <button className="bg-[#A6F200] w-[180px] h-[42px] sm:w-32 sm:h-10 items-center hover:bg-[#003249] hover:text-white">
                  <p className="text-sm font-light">Load more jobs</p>
                </button>
              </div>
            )}
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Careers;
