import React from "react";

const Dsolutions = () => {
  return (
    <div className=" pl-2 w-full  sm:my-20 py-8">
      <div className="flex flex-col">
        <p className="font-light sm:mx-28 sm:text-5xl text-3xl">
          Our Solutions
        </p>
        <div className="flex flex-col mt-4 sm:mt-16 space-y-5 sm:space-y-5 pb-10 sm:flex-col justify-evenly sm:ml-32">
          <div className="flex flex-col sm:flex-row sm:space-x-16">
            <div className="flex flex-col pt-10">
              <div className="flex gap-3">
                <span className=" w-2 mt-[6px] h-2 bg-[#A6F200] rounded-full"></span>
                <div>
                  <h2 className="font-medium sm:text-lg">
                    Predictive analytics & forecasting{" "}
                  </h2>
                  <p className="font-light text-[14px] sm:text-sm max-w-xs pt-3">
                    Unlock the power of data with our predictive analytics
                    solutions, helping you forecast future trends and make
                    data-driven decisions for your business.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col pt-10 mb-12">
              <div className="flex gap-3">
                <span className=" w-2 mt-[6px] h-2 bg-[#A6F200] rounded-full"></span>
                <div>
                  <h2 className="font-medium sm:text-lg">Machine Learning </h2>
                  <p className="font-light text-[14px] sm:text-sm max-w-xs pt-3">
                    Harness the potential of machine learning to automate
                    processes, optimise operations, and gain valuable insights
                    from your data.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:mt-10">
              <div className="flex items-start gap-3">
                <span className=" w-2 mt-[6px] h-2 bg-[#A6F200] rounded-full"></span>
                <div>
                  <h2 className="font-medium sm:text-lg">
                    Natural language processing (NLP){" "}
                  </h2>
                  <p className="font-light text-[14px] sm:text-sm max-w-xs pt-3">
                    Gain a deeper understanding of your customers' sentiments
                    and extract meaningful information from unstructured data,
                    such as customer feedback and social media posts, using our
                    advanced NLP techniques.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row sm:space-x-20">
            <div className="flex flex-col mt-6">
              <div className="flex items-start gap-3">
                <span className=" w-2 mt-[6px] h-2 bg-[#A6F200] rounded-full"></span>
                <div>
                  <h2 className="font-medium sm:text-lg">
                    Fraud detection & prevention{" "}
                  </h2>
                  <p className="font-light text-[14px] sm:text-sm max-w-xs sm:max-w-[19rem] sm:pt-3">
                    Safeguard your business against fraudulent activities with
                    our cutting-edge fraud detection and prevention solutions,
                    leveraging AI and data analysis to detect patterns and
                    anomalies in real-time.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col mt-10 sm:mt-5">
              <div className="flex items-start gap-3">
                <span className=" w-2 mt-[6px] h-2 bg-[#A6F200] rounded-full"></span>
                <div>
                  <h2 className="font-medium sm:text-lg">
                    Marketing Analytics{" "}
                  </h2>
                  <p className="font-light text-[14px] sm:text-sm max-w-xs pt-3">
                    Maximise the impact of your marketing efforts with our
                    marketing analytics solutions, providing you with valuable
                    insights into customer behaviour, campaign performance, and
                    ROI to drive your marketing strategy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dsolutions;

// <div className='flex flex-col pt-10'>
// <div className="flex gap-2">
// <span className=" w-2 mt-1 h-2 bg-green-300 rounded-full"></span>
// <div>
//     <h2 className="font-bold sm:text-sm">Machine Learning</h2>
//     <p className='font-light text-xs sm:text-sm max-w-xs pt-3'>Harness the potential of machine learning to automate processes, optimise operations, and gain valuable insights from your data.</p>
// </div>
// </div>
// </div>
