import React, { useState } from "react";

import subscription from "../assets/subscription.png";
import axios from "axios";
import { motion } from "framer-motion";

const Subscribe = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const [isloading, setIsLoading] = useState(false);

  const handleSubscribe = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    try {
      const rs = await axios.post(
        "https://syx-server-747dfeee5139.herokuapp.com/v1/subscribe",
        {
          email: email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setIsLoading(false);
      setSuccess(true);
    } catch (err) {
      console.log(err);
      setError(err.response.data.message);
    }
  };

  return (
    <div className="mt-[3rem] md:mt-24 px-4 lg:px-[6.25rem]">
      <div
        className="mb-24 w-full h-[260px] lg:px-[6.25rem] px-6 pt-[40px] lg:block hidden "
        style={{
          background: `url(${subscription})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top right",
        }}
      >
        <div>
          {success ? (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.5 }}
              className="text-[40px] text-[#C5D5EA]"
            >
              <p>You subscribed to syxthSense</p>
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="flex text-white flex-col"
            >
              <p className="font-light text-[40px]">Subscribe to SyxthSense</p>
              <p className="max-w-xs text-sm text-[#C5D5EA]">
                Get insights from our experts and stay up to date with the
                latest information about SyxLabs
              </p>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="flex flex-row"
              >
                <form
                  onSubmit={handleSubscribe}
                  className="w-[700px] space-x-6"
                >
                  <motion.input
                    type="email"
                    placeholder="Your email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="bg-transparent w-[349px] mt-6 px-5 h-12 focus:outline-none focus:bg-trasparent border-2 border-[#7C9299] placeholder-[#7C9299]"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                  />
                  <motion.button
                    type="submit"
                    className="items-center font-normal text-base text-[#003249] w-[136px] h-[48px] bg-[#A6F200] mt-6"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                  >
                    {isloading ? "Loading ..." : "Subscribe"}
                  </motion.button>
                </form>
              </motion.div>
            </motion.div>
          )}
        </div>
      </div>

      {/* mobile sub */}

      <div className="bg-[#7C9299] lg:hidden block min-h-[338px] py-[16px] pb-[24px] px-[16px]  mb-[80px] ">
        {success ? (
          <>
            <h1 className="text-primary font-light text-[32px] ">
              You subscribed to syxthSense
            </h1>
          </>
        ) : (
          <>
            <h1 className="text-primary font-light text-[32px] ">
              Subscribe to SyxthSense
            </h1>
            <p className="text-primary font-normal text-[16px] my-4">
              Get insights from our experts and stay up to date with the latest
              information about SyxLabs
            </p>

            <form onSubmit={handleSubscribe}>
              <input
                type="email"
                placeholder="Your email"
                required
                onChange={(e) => setEmail(e.target.value)}
                className="bg-transparent
            rounded-none
            px-[16px] h-[46px] py-[24px] text-sm w-full  focus:outline-none focus:bg-trasparent border border-primary placeholder-primary"
              />

              <button
                className="items-center font-normal text-base text-[#003249] w-[140px] h-[40px] bg-[#A6F200] mt-[24px] "
                type="submit"
              >
                {isloading ? "Loading ..." : "Subscribe"}
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default Subscribe;
