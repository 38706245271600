import React, { useState, useEffect } from "react";
import { v4 } from "uuid";

import axios from "axios";

import close from "../assets/close.svg";
import thumbnails from "../assets/thumbnail.svg";

import { Link } from "react-router-dom";

const Join = () => {
  const [jobs, setJobs] = useState([]);

  const [isFormVisible, setIsFormVisible] = useState(false);

  const [selectedJobId, setSelectedJobId] = useState(null);

  const [formJobId, setFormJobId] = useState(null);

  const [thumbnail, setThumbnail] = useState("");

  const handleApplyClick = (jobId) => {
    setIsFormVisible(true);
    setFormJobId(jobId);
  };

  const handleCloseClick = () => {
    setIsFormVisible(false);
    setFormJobId(null);
  };

  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    letter: "",
    resume: null,
  });

  const handleChange = (event) => {
    const { name, value, files } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
      resume: files && files[0],
    });

    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        setThumbnail(reader.result);
      };
      reader.readAsDataURL(files[0]);
    } else {
      setThumbnail(""); // clear the thumbnail when no file is selected
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // perform form submission logic here using formValues
    setIsFormVisible(false); // hide the form after submission
  };

  const handleClick = () => {
    window.location.href = "/careers#roles";
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://syx-server-747dfeee5139.herokuapp.com/v1/jobs"
        );
        // console.log(response.data); // Check the data format in the console
        setJobs(response.data.data);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const titleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <section className="w-full lg:px-[6.25rem] p-0 flex my-[3rem] lg:my-[7.5rem]">
      <div className="bg-white sm:bg-[#FBFBFB] px-[1.5rem] md:gap-8 md:py-[4.19rem] md:px-[3rem] flex flex-col sm:flex-row sm:w-full sm:justify-between ">
        <div className="flex flex-col gap-5 sm:gap-6 sm:pt-7">
          <h1 className="text-[32px] sm:text-[48px] font-normal">
            Join our team
          </h1>
          <p className="text-[16px] font-[400] max-w-2xl sm:max-w-lg leading-[24px]">
            We are always on the lookout for talented individuals who are
            passionate about blockchain and AI and want to help us build the
            future. <br></br>
            Join our team of experts and work on exciting projects that make a
            difference.
          </p>
        </div>
        <div className="">
          <div className="flex flex-col gap-4 mt-16 sm:mt-7">
            {jobs.slice(0, 2).map((job) => (
              <div
                className="flex flex-row w-full bg-[#ECECEC] px-5 py-2 justify-between"
                key={v4()}
              >
                <div className="flex flex-col gap-2">
                  <p className="font-[500] text-[16px]">
                    {titleCase(job.title)}
                  </p>
                  <p className="font-[400] text-[#7C9299] text-[14px]">
                    {titleCase(job.location)}
                  </p>
                </div>
                <Link to={`/blockeng/${job.id}`}>
                  <button
                    onClick={handleApplyClick}
                    className="border-[1px] mt-2 w-[84px] h-[33px] border-black"
                  >
                    <p className="font-light text-[14px]">Apply</p>
                  </button>
                </Link>
              </div>
            ))}
          </div>
          <button
            onClick={() => handleClick()}
            className="w-[140px] sm:w-[176px] h-[40px] sm:ml-[13.7rem] relative bg-[#003249] hover:bg-[#A6F200] mt-8"
          >
            <p className="font-[400] text-[12px] sm:text-[14px]  text-white">
              See all open roles
            </p>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Join;

// {isFormVisible && (
//   <div className="fixed overflow-y-scroll top-0 left-0 right-0 bottom-0 w-full h-[100vh] bg-[#003249] text-[#0E0E0E] bg-opacity-[30%] flex justify-center items-center">
//     <form
//       onSubmit={handleSubmit}
//       className="bg-white mt-48 w-[343px] sm:w-[812px] sm:h-[794px]"
//     >
//       {jobs.slice(0, 2).map((job) => (
//         <div className="flex flex-col px-3 sm:px-10 py-6">
//           <div className="flex sm:flex-row justify-between sm:justify-between">
//             <p className="font-[600] mt-4 sm:mt-0 text-[20px] max-w-[15rem] sm:max-w-xl sm:text-[32px]">
//               Apply for {titleCase(job.title)}
//             </p>
//             <button className="" onClick={handleCloseClick}>
//               <img src={close} alt="" className="w-[28px] h-[28px]" />
//             </button>
//           </div>
//           <div className="flex flex-col mt-6 sm:mt-16">
//             <div className="flex flex-col sm:flex-row gap-4">
//               <div className="flex flex-col space-y-4">
//                 <p className="font-[500] text-[14px] after:content-['*'] after:ml-0.5 after:text-red-500">
//                   First Name
//                 </p>
//                 <input
//                   type="text"
//                   name="firstName"
//                   value={formValues.firstName}
//                   onChange={handleChange}
//                   placeholder="First Name"
//                   className="w-[311px] h-[40px] sm:w-[350px] px-4 placeholder:text-[#989898] placeholder:font-[400] placeholder:text-[16px] sm:h-[40px] border-[1px] border-[#D7D7D7]"
//                 />
//               </div>
//               <div className="flex flex-col space-y-4">
//                 <p className="font-[500] text-[14px] after:content-['*'] after:ml-0.5 after:text-red-500">
//                   Last Name
//                 </p>
//                 <input
//                   type="text"
//                   name="lastName"
//                   value={formValues.lastName}
//                   onChange={handleChange}
//                   placeholder="Last Name"
//                   className="w-[311px] h-[40px] sm:w-[350px] px-4 placeholder:text-[#989898] placeholder:font-[400] placeholder:text-[16px] sm:h-[40px] border-[1px] border-[#D7D7D7]"
//                 />
//               </div>
//             </div>

//             <div className="flex flex-col sm:flex-row gap-4 mt-4 sm:mt-8">
//               <div className="flex flex-col space-y-4">
//                 <p className="font-[500] text-[14px] after:content-['*'] after:ml-0.5 after:text-red-500">
//                   Email
//                 </p>
//                 <input
//                   type="email"
//                   name="email"
//                   value={formValues.email}
//                   onChange={handleChange}
//                   placeholder="Email"
//                   className="w-[311px] h-[40px] sm:w-[350px] px-4 placeholder:text-[#989898] placeholder:font-[400] placeholder:text-[16px] sm:h-[40px] border-[1px] border-[#D7D7D7]"
//                 />
//               </div>
//               <div className="flex flex-col space-y-4">
//                 <p className="font-[500] text-[14px] after:content-['*'] after:ml-0.5 after:text-red-500">
//                   Phone
//                 </p>
//                 <input
//                   type="tel"
//                   name="phone"
//                   value={formValues.phone}
//                   onChange={handleChange}
//                   placeholder="Phone"
//                   className="w-[311px] h-[40px] sm:w-[350px] px-4 placeholder:text-[#989898] placeholder:font-[400] placeholder:text-[16px] sm:h-[40px] border-[1px] border-[#D7D7D7]"
//                 />
//               </div>
//             </div>

//             <div className="flex flex-col space-y-4 mt-8">
//               <p className="font-[500] text-[14px] after:content-['*'] after:ml-0.5 after:text-red-500">
//                 Cover Letter
//               </p>
//               <textarea
//                 type="message"
//                 name="letter"
//                 value={formValues.letter}
//                 onChange={handleChange}
//                 rows="20"
//                 cols="70"
//                 className="w-[311px] h-[160px] sm:w-[716px] px-4 py-4 placeholder:text-[#989898] placeholder:font-[400] placeholder:text-[16px] sm:h-[160px] border-[1px] border-[#D7D7D7]"
//               />
//             </div>

//             <div className="flex flex-col space-y-4 mt-8">
//               <p className="font-[500] text-[14px] after:content-['*'] after:ml-0.5 after:text-red-500">
//                 Attach your CV/Resume
//               </p>
//               <div className="relative w-[311px] h-[64px] sm:w-[716px] sm:h-[64px] border-dashed border-[1px] border-[#D7D7D7] overflow-hidden">
//                 <input
//                   type="file"
//                   name="resume"
//                   onChange={handleChange}
//                   className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
//                 />
//                 <div className="flex flex-row items-center mt-4 gap-2 justify-center">
//                   <img src={thumbnails} alt="Thumbnail" />

//                   <p className="text-[14px] text-[#505155] font-[400]">
//                     Upload file or drag and drop here
//                   </p>
//                 </div>
//               </div>
//             </div>

//             <button
//               type="submit"
//               className="w-[311px] h-[40px] sm:w-[152px] sm:h-[40px] mt-12 bg-[#A6F200] flex justify-center items-center"
//             >
//               <p className="text-[14px] font-[400]">
//                 Submit Application
//               </p>
//             </button>
//           </div>
//         </div>
//       ))}
//     </form>
//   </div>
// )}
