import React from "react";

import illustration3 from "../../assets/illustrations3.png";
import Asolutions from "./Solutions/Asolutions";
import Header from "../Header";

import Relblog from "./Relblog";
import banner from "../../assets/Banner.png";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import ai from "../../assets/brain.svg";
import {
  FADE_DOWN_ANIMATION_VARIANTS,
  variants1,
} from "../../constants/fmvariants";

const AI = () => {
  return (
    <div className="">
      <Header />
      <section className=" flex flex-col sm:flex sm:flex-col mt-0 lg:mt-[73px]">
        <motion.div
          className="flex   lg:h-[520px]  sm:flex-row flex-col xl:justify-between "
          initial="hidden"
          animate="show"
          viewport={{ once: true }}
          variants={{
            hidden: {},
            show: {
              transition: {
                staggerChildren: 0.15,
              },
            },
          }}
        >
          <div className="flex flex-col mt-[40px] lg:px-[147px] ">
            <motion.img
              src={illustration3}
              alt="illustration"
              initial="hidden"
              animate="visible"
              transition={{ duration: 1 }}
              variants={variants1}
              className="flex lg:w-[375px] xl:justify-end lg:mt-18 sm:hidden"
            />

            <motion.div
              variants={FADE_DOWN_ANIMATION_VARIANTS}
              className="border-[1px] border-primary w-[88px] h-[36px] py-[12px] px-[16px]  rounded-full flex items-center justify-center mx-4 lg:my-0 my-[24px]"
            >
              <p className="font-medium text-xs text-primary">SERVICES</p>
            </motion.div>

            <motion.div
              className="flex flex-col lg:h-72 px-4 lg:-mb-0 mb-[64px]"
              variants={FADE_DOWN_ANIMATION_VARIANTS}
            >
              <p className="font-light lg:text-[48px] text-[32px] max-w-lg mt-12 lg:mt-[32px]">
                Customised AI algorithms
              </p>
              <p className="font-normal lg:mt-[px] mt-[48px] text-secondary text-[16px]  lg:w-[482px]">
                Our cutting-edge technology can help you extract valuable
                insights from your data and develop custom solutions that meet
                your specific needs.
              </p>
              <div className=" mt-[24px]">
                <button className="flex justify-center items-center flex-col gap-4 text-[#A6F200] bg-[#003249] h-[40px] w-[140px] sm:w-[120px] sm:h-[40px] hover:bg-[#A6F200] hover:text-[#003249] cursor-pointer">
                  <p className="text-sm">
                    <Link to="/contact">Talk to us</Link>
                  </p>
                </button>
              </div>
            </motion.div>
          </div>

          <motion.div
            className="bg-[#E5F1FF] items-center  w-[720px]  sm:flex sm:justify-center hidden sm:block"
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.5 }}
            variants={variants1}
          >
            <img src={illustration3} alt="" className="  " />
          </motion.div>
        </motion.div>
        <motion.div 
        
        initial="hidden"
        animate="show"
        viewport={{ once: true }}
        variants={{
          hidden: {},
          show: {
            transition: {
              staggerChildren: 0.15,
            },
          },
        }}
        className=" bg-[#A6F200] h-full lg:h-[288px] flex items-center py-[48px]  lg:px-[147px] px-4  ">
          <motion.p
          
          variants={FADE_DOWN_ANIMATION_VARIANTS}
          className="font-normal lg:text-[32px] text-[20px] lg:w-[706px] lg:h-[192px] h-full text-primary">
            Because of this, we collaborate closely with you to create unique AI
            solutions that are catered to your  particular business
            requirements.
          </motion.p>
        </motion.div>
        <div className="">
          <Asolutions />
        </div>

        <div
          className="w-full mt-12 relative h-[160px] mb-[100px] flex justify-center items-center"
          style={{
            backgroundImage: `url(${banner})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className=" w-full items-center  flex justify-center  lg:flex-row flex-col lg:space-x-[49px] lg:space-y-0 space-y-[16px] ">
            <p className="lg:text-[40px] text-[24px] font-light text-white">
              Take the next step.{" "}
            </p>
            <button
              className="items-center w-[120px] h-[42px] font-normal bg-[#A6F200] text-primary text-sm
            
            hover:bg-[black] hover:text-[#fff]  py-[12px] px-[12px]
            "
            >
              <Link to="/contact">Contact Us</Link>
            </button>
          </div>
        </div>

        <div className="bg-[#F9F9F9]">{/* <Relblog /> */}</div>
      </section>
      <Footer />
    </div>
  );
};

export default AI;
