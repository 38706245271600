import React from "react";

const Asolutions = () => {
  return (
    <div className=" w-full  lg:px-[146px] px-[16px] lg:pt-[96px] pt-[80px] ">
    <div className="flex flex-col">
      <p className="font-light text-primary lg:text-[40px] text-[32px]">
        Our Solutions
      </p>
      <div className="flex flex-col  pb-10 justify-evenly lg:mt-[96px] mt-[48px] ">
        <div className="flex flex-col sm:flex-row space-x-0 lg:space-x-16 ">
          <div className="flex flex-col">
            <div className="flex gap-3 mb-12">
              <span className=" w-2 mt-[6px] h-2 bg-[#A6F200] rounded-full"></span>
              <div>
                <h2 className="font-medium text-base lg:text-lg max-w-xs sm:max-w-[17rem] text-primary ">
                Computer vision and image recognition
                </h2>
                <p className="font-light text-[14px]  max-w-xs pt-3 text-secondary">
                Harness the power of AI to develop computer vision solutions that enable accurate object detection, image recognition, and visual data analysis for various applications and industries.
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col mb-12">
            <div className="flex gap-3">
              <span className=" w-2 mt-[6px] h-2 bg-[#A6F200] rounded-full"></span>
              <div>
                <h2 className="font-medium text-base lg:text-lg max-w-xs sm:max-w-[17rem] text-primary ">
                Voice assistants and chatbots
                </h2>
                <p className="font-light text-[14px]  max-w-xs pt-3 text-secondary">
                Create intelligent voice assistants and chatbots that provide personalised and interactive experiences, leveraging natural language processing and machine learning to understand and respond to user queries.
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex items-start gap-3">
              <span className=" w-2 mt-[6px] h-2 bg-[#A6F200] rounded-full"></span>
              <div>
                <h2 className="font-medium text-base lg:text-lg max-w-xs sm:max-w-[17rem] text-primary ">
                Recommendation engines
                </h2>
                <p className="font-light text-[14px]  max-w-xs pt-3 text-secondary">
                Utilise advanced algorithms and AI techniques to build recommendation engines that deliver personalised suggestions, enhancing user engagement and driving conversions for your platform.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row  space-x-0 lg:space-x-16 ">
          <div className="flex flex-col mt-6">
            <div className="flex items-start gap-3">
              <span className=" w-2 mt-[6px] h-2 bg-[#A6F200] rounded-full"></span>
              <div>
                <h2 className="font-medium text-base lg:text-lg max-w-xs sm:max-w-[17rem] text-primary ">
                Autonomous vehicles and robotics
                </h2>
                <p className="font-light text-[14px]  max-w-xs pt-3 text-secondary">
                Pioneer the future of mobility with our AI-driven solutions for autonomous vehicles and robotics, enabling intelligent navigation, perception, and decision-making capabilities for enhanced safety and efficiency.
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-10 sm:mt-5">
            <div className="flex items-start gap-3">
              <span className=" w-2 mt-[6px] h-2 bg-[#A6F200] rounded-full"></span>
              <div>
                <h2 className="font-medium text-base lg:text-lg  text-primary ">
                Predictive maintenance and asset management
                </h2>
                <p className="font-light text-[14px]  max-w-xs pt-3 text-secondary">
                Optimise your operations and reduce downtime with our AI-based predictive maintenance and asset management solutions, detect potential issues, schedule maintenance, and extend the lifespan of your assets.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Asolutions;
