import React from "react";

const Csolutions = () => {
  return (
    <div className=" w-full  lg:px-[146px] px-[16px] lg:pt-[96px] pt-[80px] ">
    <div className="flex flex-col">
      <p className="font-light text-primary lg:text-[40px] text-[32px]">
        Our Solutions
      </p>
      <div className="flex flex-col  pb-10 justify-evenly lg:mt-[96px] mt-[48px] ">
        <div className="flex flex-col sm:flex-row space-x-0 lg:space-x-16 ">
          <div className="flex flex-col">
            <div className="flex gap-3 mb-12">
              <span className=" w-2 mt-[6px] h-2 bg-[#A6F200] rounded-full"></span>
              <div>
                <h2 className="font-medium text-base lg:text-lg max-w-xs sm:max-w-[17rem] text-primary ">
                Business strategy and transformation
                </h2>
                <p className="font-light text-[14px]  max-w-xs pt-3 text-secondary">
                Develop strategic initiatives and navigate transformative changes, ensuring long-term success and growth in a rapidly evolving marketplace.      </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col mb-12">
            <div className="flex gap-3">
              <span className=" w-2 mt-[6px] h-2 bg-[#A6F200] rounded-full"></span>
              <div>
                <h2 className="font-medium text-base lg:text-lg max-w-xs sm:max-w-[17rem] text-primary ">
                Organisational change management
                </h2>
                <p className="font-light text-[14px]  max-w-xs pt-3 text-secondary">
                Develop strategic initiatives and navigate transformative changes, ensuring long-term success and growth in a rapidly evolving marketplace.   </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex items-start gap-3">
              <span className=" w-2 mt-[6px] h-2 bg-[#A6F200] rounded-full"></span>
              <div>
                <h2 className="font-medium text-base lg:text-lg max-w-xs sm:max-w-[17rem] text-primary ">
                IT governance and risk management
                </h2>
                <p className="font-light text-[14px]  max-w-xs pt-3 text-secondary">
                Ensure your IT operations align with business objectives, while mitigating risks and enhancing cybersecurity measures to safeguard your digital assets.
                 </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row  space-x-0 lg:space-x-16 ">
          <div className="flex flex-col mt-6">
            <div className="flex items-start gap-3">
              <span className=" w-2 mt-[6px] h-2 bg-[#A6F200] rounded-full"></span>
              <div>
                <h2 className="font-medium text-base lg:text-lg max-w-xs sm:max-w-[17rem] text-primary ">
                Customer experience and engagement
                </h2>
                <p className="font-light text-[14px]  max-w-xs pt-3 text-secondary">
                Effectively manage and implement change initiatives, support employees through transitions and foster a culture of adaptability and resilience.    </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-10 sm:mt-5">
            <div className="flex items-start gap-3">
              <span className=" w-2 mt-[6px] h-2 bg-[#A6F200] rounded-full"></span>
              <div>
                <h2 className="font-medium text-base lg:text-lg  text-primary ">
                Process improvement and optimization
                </h2>
                <p className="font-light text-[14px]  max-w-xs pt-3 text-secondary">
                Analyse your existing processes and get tailored recommendations to streamline operations, increase efficiency, and drive cost savings through process improvement and optimization.
                   </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Csolutions;
