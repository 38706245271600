const styles = {
  paddingX: "lg:px-[64px] px-[16px]",
  paddingY: "sm:py-16 py-6",
  padding: " px-6 sm:py-16 py-10",

  heroHeadText:
    "font-light text-primary lg:text-[96px] text-[48px] mt-2 text-center",
  heroSubText:
    "text-primary text-center font-normal lg:text-[24px] sm:text-[16px] xs:text-[16px] text-[16px] lg:leading-[35px]",

  sectionHeadText:
    "text-white font-black md:text-[60px] sm:text-[50px] xs:text-[40px] text-[30px]",
  sectionSubText:
    "sm:text-[18px] text-[14px] text-secondary uppercase tracking-wider",
};

export { styles };
