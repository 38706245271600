import React, { useState, useEffect } from "react";
import { v4 } from "uuid";
import Header from "../Header";
import Footer from "../Footer";

import location2 from "../../assets/location2.svg";
import arrspace from "../../assets/arrspace.svg";
import peeps from "../../assets/peeps.svg";
import arrowright from "../../assets/arrowright.png";

import close from "../../assets/close.svg";
import thumbnails from "../../assets/thumbnail.svg";

import { Link, useNavigate, useParams } from "react-router-dom";

import marks from "../../assets/marks.png";
import marks2 from "../../assets/marksmobile.svg";

import axios from "axios";

const Blockeng = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [jobs, setJobs] = useState([]);
  const [similarJobs, setSimilarJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state
  const [isSubmitted, setIsSubmitted] = useState(false); // Add isSubmitted state
  const [selectedFile, setSelectedFile] = useState(null);

  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    letter: "",
    resume: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://syx-server-747dfeee5139.herokuapp.com/v1/jobs"
        );
        setJobs(response.data.data);
      } catch (error) {
        console.log("Error fetching data:", error);
        navigate.back();
      }
    };

    const fetchSimilarJobsData = async (id) => {
      try {
        const response = await axios.get(
          `https://syx-server-747dfeee5139.herokuapp.com/v1/jobs/similar/${id}`
        );
        setSimilarJobs(response.data.data);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    if (id) {
      fetchSimilarJobsData(id);
    }
    fetchData();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append("firstName", formValues.firstName);
    formData.append("lastName", formValues.lastName);
    formData.append("phone", formValues.phone);
    formData.append("email", formValues.email);
    formData.append("letter", formValues.letter);
    formData.append("resume", formValues.resume); // Append the file to FormData

    axios
      .post(
        "https://syx-server-747dfeee5139.herokuapp.com/v1/jobs/apply",
        formData
      )
      .then(() => {
        // Handle success, e.g., show a success message
        setIsLoading(false);
        setIsSubmitted(true); // Set isSubmitted to true after successful submission
        // Optionally, you can reset the form after successful submission
        setFormValues({
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          letter: "",
          resume: null,
        });
        setThumbnail(""); // Clear the thumbnail after successful submission
      })
      .catch((error) => {
        // Handle error, e.g., show an error message
        console.error("Error submitting application:", error.message);
        setIsLoading(false);
      });
  };

  const handleApplyClick = () => {
    setIsFormVisible(true);
  };

  const handleCloseClick = () => {
    setIsFormVisible(false);
  };

  const [, setThumbnail] = useState("");

  const handleChange = (event) => {
    const { name, value, files } = event.target;

    if (event.target.type === "file") {
      setFormValues({
        ...formValues,
        [name]: value,
        resume: files && files[0],
      });

      setSelectedFile(files && files[0]);
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleClick = () => {
    window.location.href = "/careers#roles";
  };

  const titleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const jobTitle = jobs.find((job) => job.id === id)?.title;
  const formattedTitle = jobTitle ? titleCase(jobTitle) : "";

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <div>
      <Header />
      <section className="mt-16 bg-[#003249] flex flex-col ">
        <div className="mt-20 flex flex-col px-5 sm:px-36">
          <button className="flex flex-row gap-3" onClick={handleClick}>
            <img src={arrowright} alt="" className="mt-[2px]" />
            <Link
              to={"/careers"}
              className="text-[12px] font-extralight text-white"
            >
              Go back to Jobs
            </Link>
          </button>

          <p className=" text-2xl mt-10 sm:mt-12 sm:text-[48px] font-light sm:font-extralight text-white">
            {formattedTitle}
          </p>

          <div className="flex mt-6 sm:mt-8 flex-row gap-3 pb-12 sm:pb-16">
            <img src={location2} alt="" />
            <p className="text-[12px] font-extralight text-white">Remote</p>
            <img src={peeps} alt="" />
            <p className="text-[12px] font-extralight text-white">
              Engineering
            </p>
          </div>
        </div>
        <div className="w-full hidden sm:block">
          <img src={marks} alt="" className=" sm:w-full" />
        </div>
        <div className=" block lg:hidden">
          <img src={marks2} alt="" className="w-full" />
        </div>
      </section>

      <div className="mt-14 sm:mt-24 px-5 sm:px-28 flex pb-48 flex-col sm:flex-row w-full sm:justify-between">
        {jobs.map((job) => {
          if (job.id === id) {
            return (
              <div className="flex-col gap-20" key={v4()}>
                <Link to={`/blockeng/${job.id}`}>
                  <div className="flex flex-col gap-5">
                    <p className="text-[18px] font-bold">Job Summary</p>
                    <p className="text-[14px] font-light leading-[21px] max-w-2xl">
                      {capitalizeFirstLetter(job.summary)}
                    </p>
                  </div>

                  <div className="flex flex-col mt-12 gap-5 max-w-md sm:max-w-2xl">
                    <p className="text-[18px] font-bold">Requirements</p>
                    <ul className="text-[14px] font-light px-5 leading-[21px] list-disc">
                      {job.requirements.map((requirement) => (
                        <li className="break-word" key={v4()}>
                          {capitalizeFirstLetter(requirement)}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="flex flex-col mt-12 gap-5 max-w-md sm:max-w-2xl">
                    <p className="text-[18px] font-bold">Experience</p>
                    <ul className="text-[14px] px-5 font-light leading-[21px] list-disc">
                      {job.experience.map((exp) => (
                        <li className="break-word" key={v4()}>
                          {capitalizeFirstLetter(exp)}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="flex flex-col mt-12 gap-5 max-w-md sm:max-w-2xl">
                    <p className="text-[18px] font-bold">What you get</p>
                    <ul className="text-[14px] font-light px-5 leading-[21px] list-disc">
                      {job.benefits.map((benefit) => (
                        <li className="break-word" key={v4()}>
                          {capitalizeFirstLetter(benefit)}
                        </li>
                      ))}
                    </ul>
                  </div>
                </Link>
              </div>
            );
          }
          return null;
        })}

        <div className="flex flex-col">
          <button
            className="sm:w-[138px] w-[343px] sm:h-[40px] h-[48px] hover:bg-[#003249] hover:text-white bg-[#A6F200] ml-2 sm:ml-0 flex justify-center items-center mt-10 sm:mt-0"
            onClick={handleApplyClick}
          >
            <p className="text-[14px] sm:font-light font-[500]">
              Apply for this job
            </p>
          </button>

          {isFormVisible && (
            <div className="fixed overflow-y-scroll top-0 left-0 right-0 bottom-0 w-full h-[100vh] bg-[#003249] text-[#0E0E0E] bg-opacity-[30%] flex justify-center items-center">
              <form
                onSubmit={handleSubmit}
                className="bg-white mt-48 w-[343px] sm:w-[812px] sm:h-[794px]"
              >
                <div className="flex flex-col px-3 sm:px-10 py-6">
                  <div className="flex sm:flex-row justify-between sm:justify-between">
                    <p className="font-[600] mt-4 sm:mt-0 text-[20px] max-w-[15rem] sm:max-w-xl sm:text-[32px]">
                      {formattedTitle}
                    </p>
                    <button className="" onClick={handleCloseClick}>
                      <img src={close} alt="" className="w-[28px] h-[28px]" />
                    </button>
                  </div>
                  <div className="flex flex-col mt-6 sm:mt-16">
                    <div className="flex flex-col sm:flex-row gap-4">
                      <div className="flex flex-col space-y-4">
                        <p className="font-[500] text-[14px] after:content-['*'] after:ml-0.5 after:text-red-500">
                          First Name
                        </p>
                        <input
                          type="text"
                          name="firstName"
                          value={formValues.firstName}
                          onChange={handleChange}
                          placeholder="First Name"
                          className="w-[311px] h-[40px] sm:w-[350px] px-4 placeholder:text-[#989898] placeholder:font-[400] placeholder:text-[16px] sm:h-[40px] border-[1px] border-[#D7D7D7]"
                        />
                      </div>
                      <div className="flex flex-col space-y-4">
                        <p className="font-[500] text-[14px] after:content-['*'] after:ml-0.5 after:text-red-500">
                          Last Name
                        </p>
                        <input
                          type="text"
                          name="lastName"
                          value={formValues.lastName}
                          onChange={handleChange}
                          placeholder="Last Name"
                          className="w-[311px] h-[40px] sm:w-[350px] px-4 placeholder:text-[#989898] placeholder:font-[400] placeholder:text-[16px] sm:h-[40px] border-[1px] border-[#D7D7D7]"
                        />
                      </div>
                    </div>

                    <div className="flex flex-col sm:flex-row gap-4 mt-4 sm:mt-8">
                      <div className="flex flex-col space-y-4">
                        <p className="font-[500] text-[14px] after:content-['*'] after:ml-0.5 after:text-red-500">
                          Email
                        </p>
                        <input
                          type="email"
                          name="email"
                          value={formValues.email}
                          onChange={handleChange}
                          placeholder="Email"
                          className="w-[311px] h-[40px] sm:w-[350px] px-4 placeholder:text-[#989898] placeholder:font-[400] placeholder:text-[16px] sm:h-[40px] border-[1px] border-[#D7D7D7]"
                        />
                      </div>
                      <div className="flex flex-col space-y-4">
                        <p className="font-[500] text-[14px] after:content-['*'] after:ml-0.5 after:text-red-500">
                          Phone
                        </p>
                        <input
                          type="tel"
                          name="phone"
                          value={formValues.phone}
                          onChange={handleChange}
                          placeholder="Phone"
                          className="w-[311px] h-[40px] sm:w-[350px] px-4 placeholder:text-[#989898] placeholder:font-[400] placeholder:text-[16px] sm:h-[40px] border-[1px] border-[#D7D7D7]"
                        />
                      </div>
                    </div>

                    <div className="flex flex-col space-y-4 mt-8">
                      <p className="font-[500] text-[14px] after:content-['*'] after:ml-0.5 after:text-red-500">
                        Cover Letter
                      </p>
                      <textarea
                        type="message"
                        name="letter"
                        value={formValues.letter}
                        onChange={handleChange}
                        rows="20"
                        cols="70"
                        className="w-[311px] h-[160px] sm:w-[716px] px-4 py-4 placeholder:text-[#989898] placeholder:font-[400] placeholder:text-[16px] sm:h-[160px] border-[1px] border-[#D7D7D7]"
                      />
                    </div>

                    <div className="flex flex-col space-y-4 mt-8">
                      <p className="font-[500] text-[14px] after:content-['*'] after:ml-0.5 after:text-red-500">
                        Attach your CV/Resume
                      </p>
                      <div className="relative w-[311px] h-[64px] sm:w-[716px] sm:h-[64px] border-dashed border-[1px] border-[#D7D7D7] overflow-hidden">
                        <input
                          type="file"
                          name="resume"
                          onChange={handleChange}
                          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                        />
                        <div className="flex flex-row items-center mt-4 gap-2 justify-center">
                          {selectedFile ? (
                            <img src={thumbnails} alt="Thumbnail" />
                          ) : (
                            <img src={thumbnails} alt="Thumbnail" /> // Placeholder thumbnail
                          )}

                          <p className="text-[14px] text-[#505155] font-[400]">
                            {selectedFile
                              ? selectedFile.name
                              : "Upload file or drag and drop here"}
                          </p>
                        </div>
                      </div>
                    </div>

                    <button
                      type="submit"
                      className=" bg-[#A6F200] text-[#003249] hover:bg-[#003249] hover:text-white w-[311px] h-[40px] sm:w-[152px] sm:h-[40px] mt-8 text-[14px] hover:bg-black hover:text-[#A6F200]"
                    >
                      {isLoading
                        ? "Loading..."
                        : isSubmitted
                        ? "Submitted!"
                        : "Submit Application"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}

          {similarJobs.length > 0 && (
            <>
              <p className="text-[18px] font-bold mt-10 sm:pl-12">
                Similar jobs
              </p>
              <div className="flex flex-col space-y-3 mt-6 sm:mt-10">
                {similarJobs.map((el) => (
                  <Link
                    to={`/blockeng/${el.id}`}
                    className="flex flex-row space-x-2 sm:ml-12"
                  >
                    <p className="text-[14px] font-light capitalize">
                      {el?.title?.toLowerCase()}
                    </p>
                    <img src={arrspace} alt="link" />
                  </Link>
                ))}
              </div>
            </>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Blockeng;

//  ullamcorper senectus molestie lectus sed. Ipsum mauris neque leo aliquet nunc neque mauris nunc.

//  ullamcorper senectus molestie lectus sed. Ipsum mauris neque leo aliquet nunc neque mauris nunc.
// Nulla non volutpat phasellus habitasse. Libero neque pharetra at curabitur. Commodo tellus porttitor risus nibh.
