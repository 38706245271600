import { motion } from "framer-motion";
import * as React from "react";
import { Link } from "react-router-dom";

const CookiePolicy = () => {
  const KEY = "accept-syxlabs-cookies";

  const hasAcceptedCookies = localStorage.getItem(KEY);

  const [show, setShow] = React.useState(!hasAcceptedCookies);

  const handleAccept = () => {
    setShow(false);
    localStorage.setItem(KEY, true);
  };

  return show ? (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0.5, y: 10 }}
      transition={{ duration: 0.2, delay: 2 }}
      className="w-full fixed bottom-0 bg-white z-50 flex justify-start p-4 md:p-8 items-start md:items-center gap-4"
    >
      <div className="flex-1 flex flex-col md:flex-row gap-3 text-[0.75rem] md:text-[0.875rem] justify-start items-start md:items-center">
        <p>
          We use cookies to make sure you have the best experience on our
          website. Fear not, we don’t sell your data to third parties. To find
          out more about the cookies, see our{" "}
          <Link to={"/privacy"} className="underline inline">
            privacy policy
          </Link>
          .
        </p>
        <button
          onClick={() => handleAccept()}
          className="whitespace-nowrap bg-[#CEFF64] text-[#0E0E0E] text-[0.875rem] px-4 py-2 rounded-[0.25rem] flex justify-center items-center"
        >
          Accept Cookies
        </button>
      </div>
      <button
        onClick={() => {
          setShow(false);
        }}
      >
        <svg
          className="hidden md:block"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.8692 12L1.83398 2.96642L2.96678 1.83362L12.0004 10.8688L21.034 1.83362L22.1668 2.96642L13.1316 12L22.1668 21.0336L21.0356 22.1648L12.0004 13.1312L2.96678 22.1648L1.83398 21.0336L10.8692 12Z"
            fill="black"
          />
        </svg>

        <svg
          className="block md:hidden"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.24612 8.00001L1.22266 1.97761L1.97786 1.22241L8.00026 7.24588L14.0227 1.22241L14.7779 1.97761L8.75439 8.00001L14.7779 14.0224L14.0237 14.7765L8.00026 8.75415L1.97786 14.7765L1.22266 14.0224L7.24612 8.00001Z"
            fill="black"
          />
        </svg>
      </button>
    </motion.div>
  ) : (
    <></>
  );
};

export default CookiePolicy;
