import Martha from "./Pages/Martha";
import Zap from "./Pages/Zap";

const Services = () => {
  return (
    <section className="bg-white w-full text-[#474545] min-h-screen">
      <div className="md:px-[6.25rem] md:pt-[6.5rem] px-6 pb-[3rem] pt-[3rem] md:pb-[4rem] md:top-[3rem] bg-white min-h-[30%]">
        <p className="md:text-[2.5rem] text-[1.75rem] max-w-sm md:max-w-xl font-normal">
          We’re building for a future powered by AI and blockchain
        </p>
      </div>
      <Zap />
      <Martha />
    </section>
  );
};

export default Services;
