import React from "react";

const Bsolutions = () => {
  return (
    <div className=" w-full  lg:px-[146px] px-[16px] lg:pt-[96px] pt-[80px] ">
      <div className="flex flex-col">
        <p className="font-light text-primary lg:text-[40px] text-[32px]">
          Our Solutions
        </p>
        <div className="flex flex-col  pb-10 justify-evenly lg:mt-[96px] mt-[48px] ">
          <div className="flex flex-col sm:flex-row space-x-0 lg:space-x-16 ">
            <div className="flex flex-col">
              <div className="flex gap-3 mb-12">
                <span className=" w-2 mt-[6px] h-2 bg-[#A6F200] rounded-full"></span>
                <div>
                  <h2 className="font-medium text-base lg:text-lg max-w-xs sm:max-w-[17rem] text-primary ">
                    Decentralized finance (DeFi) & DApp Development{" "}
                  </h2>
                  <p className="font-light text-[14px]  max-w-xs pt-3 text-secondary">
                    Unlock the potential of blockchain with our expertise in
                    developing decentralized finance solutions and decentralized
                    applications (DApps), revolutionising the way financial
                    transactions and services are conducted.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col mb-12">
              <div className="flex gap-3">
                <span className=" w-2 mt-[6px] h-2 bg-[#A6F200] rounded-full"></span>
                <div>
                  <h2 className="font-medium text-base lg:text-lg max-w-xs sm:max-w-[17rem] text-primary ">
                    Smart Contract Development & Auditing{" "}
                  </h2>
                  <p className="font-light text-[14px]  max-w-xs pt-3 text-secondary">
                    Create secure and reliable smart contracts with our
                    development and auditing services, ensuring transparency,
                    automation, and trust in your blockchain-based transactions.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex items-start gap-3">
                <span className=" w-2 mt-[6px] h-2 bg-[#A6F200] rounded-full"></span>
                <div>
                  <h2 className="font-medium text-base lg:text-lg max-w-xs sm:max-w-[17rem] text-primary ">
                    Non-Fungible Tokenization
                  </h2>
                  <p className="font-light text-[14px]  max-w-xs pt-3 text-secondary">
                    Dive into the world of digital assets with our non-fungible
                    token (NFT) solutions, enabling unique tokenization of
                    digital collectibles, art, and other assets on the
                    blockchain.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row  space-x-0 lg:space-x-16 ">
            <div className="flex flex-col mt-6">
              <div className="flex items-start gap-3">
                <span className=" w-2 mt-[6px] h-2 bg-[#A6F200] rounded-full"></span>
                <div>
                  <h2 className="font-medium text-base lg:text-lg max-w-xs sm:max-w-[17rem] text-primary ">
                    Cryptocurrency Exchange Development
                  </h2>
                  <p className="font-light text-[14px]  max-w-xs pt-3 text-secondary">
                    Establish your own cryptocurrency exchange platform with our
                    comprehensive development services, empowering users to
                    trade digital currencies securely and efficiently.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col mt-10 sm:mt-5">
              <div className="flex items-start gap-3">
                <span className=" w-2 mt-[6px] h-2 bg-[#A6F200] rounded-full"></span>
                <div>
                  <h2 className="font-medium text-base lg:text-lg  text-primary ">
                    Blockchain Security
                  </h2>
                  <p className="font-light text-[14px]  max-w-xs pt-3 text-secondary">
                    Safeguard your blockchain ecosystem with our robust security
                    solutions, leveraging encryption, cryptography, and other
                    advanced techniques to protect against cyber threats,
                    ensuring the integrity and confidentiality of your data and
                    transactions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bsolutions;
